import React, { useEffect, useState } from "react";
import { ko } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "./css/Calender.css";
import $ from "jquery";

function Calender() {
  const [date, setDate] = useState();

  function color_change() {
    $(".nice-dates .date-range input").css("color", "#000");
  }

  return (
    <DatePicker
      date={date}
      onDateChange={setDate}
      locale={ko}
      format="yyyy-MM-dd"
      className="DatePicker"
    >
      {({ inputProps, focused }) => (
        <input
          className={"input" + (focused ? " -focused" : "")}
          {...inputProps}
          onClick={color_change}
          placeholder="날짜 선택"
        />
      )}
    </DatePicker>
  );
}
export default Calender;

import React, { Component } from "react";
import "./css/Diagnosis_2.css";
import $ from "jquery";

class Diagnosis_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guaiac: null, //잠혈
      bilirubin: null, //빌리루빈
      urobilinogen: null, //우로빌리노겐
      ketone: null, //케톤체
      protein: null, //단백질
      nitrituria: null, //아질산염
      glucose: null, //포도당
      ph: null, //ph
      gravity: null, //비중
      leukocyte: null, //백혈구

      guaiac_score: null, //잠혈 점수
      bilirubin_score: null, //빌리루빈 점수
      urobilinogen_score: null, //우로빌리노겐 점수
      ketone_score: null, //케톤체 점수
      protein_score: null, //단백질 점수
      nitrituria_score: null, //아질산염 점수
      glucose_score: null, //포도당 점수
      ph_score: null, //ph 점수
      gravity_score: null, //비중 점수
      leukocyte_score: null, //백혈구 점수
    };
  }

  componentDidMount() {
    this.guaiac(); // 잠혈
    this.bilirubin(); // 빌리루빈
    this.urobilinogen(); //우로빌리노겐
    this.ketone(); //케톤체
    this.protein(); //단백질
    this.nitrituria(); //아질산염
    this.glucose(); //포도당
    this.ph(); //ph
    this.gravity(); //비중
    this.leukocyte(); //백혈구
  }

  // 잠혈
  guaiac = () => {
    const values = [
      this.props.guaiacArrre0,
      this.props.guaiacArrre1,
      this.props.guaiacArrre2,
      this.props.guaiacArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        guaiac: "좋음",
        guaiac_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        guaiac: "적절",
        guaiac_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        guaiac: "주의",
        guaiac_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        guaiac: "경고",
        guaiac_score: 25,
      });
    } else {
      this.setState({
        guaiac: "X",
        guaiac_score: 0,
      });
    }
  };

  //빌리루빈
  bilirubin = () => {
    const values = [
      this.props.bilirubinArrre0,
      this.props.bilirubinArrre1,
      this.props.bilirubinArrre2,
      this.props.bilirubinArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        bilirubin: "좋음",
        bilirubin_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        bilirubin: "적절",
        bilirubin_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        bilirubin: "주의",
        bilirubin_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        bilirubin: "경고",
        bilirubin_score: 25,
      });
    } else {
      this.setState({
        bilirubin: "X",
        bilirubin_score: 0,
      });
    }
  };

  //우로빌리노겐
  urobilinogen = () => {
    const values = [
      this.props.urobilinogenArrre0,
      this.props.urobilinogenArrre1,
      this.props.urobilinogenArrre2,
      this.props.urobilinogenArrre3,
      this.props.urobilinogenArrre4,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        urobilinogen: "좋음",
        urobilinogen_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        urobilinogen: "적절",
        urobilinogen_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        urobilinogen: "주의",
        urobilinogen_score: 50,
      });
    } else if (minIndex == 3 || minIndex == 4) {
      this.setState({
        urobilinogen: "경고",
        urobilinogen_score: 25,
      });
    } else {
      this.setState({
        urobilinogen: "X",
        urobilinogen_score: 0,
      });
    }
  };

  //케톤체
  ketone = () => {
    const values = [
      this.props.ketoneArrre0,
      this.props.ketoneArrre1,
      this.props.ketoneArrre2,
      this.props.ketoneArrre3,
      this.props.ketoneArrre4,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    // console.log(values)
    if (minIndex == 0) {
      this.setState({
        ketone: "좋음",
        ketone_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        ketone: "적절",
        ketone_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        ketone: "주의",
        ketone_score: 50,
      });
    } else if (minIndex == 4) {
      this.setState({
        ketone: "경고",
        ketone_score: 25,
      });
    } else {
      this.setState({
        ketone: "X",
        ketone_score: 0,
      });
    }
  };

  //단백질
  protein = () => {
    const values = [
      this.props.proteinArrre0,
      this.props.proteinArrre1,
      this.props.proteinArrre2,
      this.props.proteinArrre3,
      this.props.proteinArrre4,
      this.props.proteinArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        protein: "좋음",
        protein_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        protein: "적절",
        protein_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        protein: "주의",
        protein_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        protein: "경고",
        protein_score: 25,
      });
    } else {
      this.setState({
        protein: "X",
        protein_score: 0,
      });
    }
  };

  //아질산염
  nitrituria = () => {
    const values = [this.props.nitrituriaArrre0, this.props.nitrituriaArrre1];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        nitrituria: "좋음",
        nitrituria_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        nitrituria: "경고",
        nitrituria_score: 25,
      });
    } else {
      this.setState({
        nitrituria: "X",
        nitrituria_score: 0,
      });
    }
  };

  //포도당
  glucose = () => {
    const values = [
      this.props.glucoseArrre0,
      this.props.glucoseArrre1,
      this.props.glucoseArrre2,
      this.props.glucoseArrre3,
      this.props.glucoseArrre4,
      this.props.glucoseArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        glucose: "좋음",
        glucose_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        glucose: "적절",
        glucose_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        glucose: "주의",
        glucose_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        glucose: "경고",
        glucose_score: 25,
      });
    } else {
      this.setState({
        glucose: "X",
        glucose_score: 0,
      });
    }
  };

  //ph
  ph = () => {
    const values = [
      this.props.phArrre0,
      this.props.phArrre1,
      this.props.phArrre2,
      this.props.phArrre3,
      this.props.phArrre4,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0 || minIndex == 1) {
      this.setState({
        ph: "좋음",
        ph_score: 100,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        ph: "적절",
        ph_score: 75,
      });
    } else if (minIndex == 4) {
      this.setState({
        ph: "주의",
        ph_score: 50,
      });
    } else {
      this.setState({
        ph: "X",
        ph_score: 0,
      });
    }
  };

  //비중
  gravity = () => {
    const values = [
      this.props.gravityArrre0,
      this.props.gravityArrre1,
      this.props.gravityArrre2,
      this.props.gravityArrre3,
      this.props.gravityArrre4,
      this.props.gravityArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        gravity: "좋음",
        gravity_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        gravity: "적절",
        gravity_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        gravity: "주의",
        gravity_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        gravity: "경고",
        gravity_score: 25,
      });
    } else {
      this.setState({
        gravity: "X",
        gravity_score: 0,
      });
    }
  };

  //백혈구
  leukocyte = () => {
    const values = [
      this.props.leukocyteArrre0,
      this.props.leukocyteArrre1,
      this.props.leukocyteArrre2,
      this.props.leukocyteArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        leukocyte: "좋음",
        leukocyte_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        leukocyte: "적절",
        leukocyte_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        leukocyte: "주의",
        leukocyte_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        leukocyte: "경고",
        leukocyte_score: 25,
      });
    } else {
      this.setState({
        leukocyte: "X",
        leukocyte_score: 0,
      });
    }
  };

  move_Diagnosis_detail = (e) => {
    window.location.href =
      "/Admin_Page/Diagnosis/Diagnosis_detail/num=" + this.props.idx;
  };

  render() {
    const createdDate = this.props.created_date.split("T")[0];

    // 총 평균 점수
    const average_score =
      (this.state.guaiac_score +
        this.state.bilirubin_score +
        this.state.urobilinogen_score +
        this.state.ketone_score +
        this.state.protein_score +
        this.state.nitrituria_score +
        this.state.glucose_score +
        this.state.ph_score +
        this.state.gravity_score +
        this.state.leukocyte_score) /
      10;

    return (
      <div className="Diagnosis_2">
        <span>
          <input type="checkbox" id={this.props.idx} name="diagnosis_check" />
        </span>
        <span className="idxDiv" style={{ display: "none" }}>
          {this.props.idx}
        </span>
        {/* <span id='user_idx' className='show_detail'>{this.props.user_idx}</span> */}
        <span
          id="img"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
        >
          <img src={this.props.img} style={{ height: "120px" }} />
        </span>
        <span
          id="guaiac_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.guaiac === "좋음" || this.state.guaiac === ""
                ? "#333333"
                : this.state.guaiac === "적절"
                ? "#0025E8"
                : this.state.guaiac === "주의"
                ? "#E86700"
                : this.state.guaiac === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.guaiac}
        </span>
        <span
          id="bilirubin_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.bilirubin === "좋음" || this.state.bilirubin === ""
                ? "#333333"
                : this.state.bilirubin === "적절"
                ? "#0025E8"
                : this.state.bilirubin === "주의"
                ? "#E86700"
                : this.state.bilirubin === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.bilirubin}
        </span>
        <span
          id="urobilinogen_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.urobilinogen === "좋음" ||
              this.state.urobilinogen === ""
                ? "#333333"
                : this.state.urobilinogen === "적절"
                ? "#0025E8"
                : this.state.urobilinogen === "주의"
                ? "#E86700"
                : this.state.urobilinogen === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.urobilinogen}
        </span>
        <span
          id="ketone_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.ketone === "좋음" || this.state.ketone === ""
                ? "#333333"
                : this.state.ketone === "적절"
                ? "#0025E8"
                : this.state.ketone === "주의"
                ? "#E86700"
                : this.state.ketone === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.ketone}
        </span>
        <span
          id="protein_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.protein === "좋음" || this.state.protein === ""
                ? "#333333"
                : this.state.protein === "적절"
                ? "#0025E8"
                : this.state.protein === "주의"
                ? "#E86700"
                : this.state.protein === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.protein}
        </span>
        <span
          id="nitrituria_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.nitrituria === "좋음" || this.state.nitrituria === ""
                ? "#333333"
                : this.state.nitrituria === "적절"
                ? "#0025E8"
                : this.state.nitrituria === "주의"
                ? "#E86700"
                : this.state.nitrituria === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.nitrituria}
        </span>
        <span
          id="glucose_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.glucose === "좋음" || this.state.glucose === ""
                ? "#333333"
                : this.state.glucose === "적절"
                ? "#0025E8"
                : this.state.glucose === "주의"
                ? "#E86700"
                : this.state.glucose === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.glucose}
        </span>
        <span
          id="ph_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.ph === "좋음" || this.state.ph === ""
                ? "#333333"
                : this.state.ph === "적절"
                ? "#0025E8"
                : this.state.ph === "주의"
                ? "#E86700"
                : this.state.ph === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.ph}
        </span>
        <span
          id="gravity_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.gravity === "좋음" || this.state.gravity === ""
                ? "#333333"
                : this.state.gravity === "적절"
                ? "#0025E8"
                : this.state.gravity === "주의"
                ? "#E86700"
                : this.state.gravity === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.gravity}
        </span>
        <span
          id="leukocyte_re"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            color:
              this.state.leukocyte === "좋음" || this.state.leukocyte === ""
                ? "#333333"
                : this.state.leukocyte === "적절"
                ? "#0025E8"
                : this.state.leukocyte === "주의"
                ? "#E86700"
                : this.state.leukocyte === "경고"
                ? "#E80025"
                : "#333333",
          }}
        >
          {this.state.leukocyte}
        </span>
        <span
          id="average_score"
          className="show_list"
          onClick={(e) => this.move_Diagnosis_detail(e)}
          style={{
            fontWeight: "bold",
            color:
              average_score <= 25
                ? "#E80025"
                : average_score <= 50
                ? "#E86700"
                : average_score <= 75
                ? "#0025E8"
                : average_score <= 100
                ? "#333333"
                : "#333333",
          }}
        >
          {average_score <= 25
            ? "경고 : " + average_score
            : average_score <= 50
            ? "주의 : " + average_score
            : average_score <= 75
            ? "적절 : " + average_score
            : average_score <= 100
            ? "좋음 : " + average_score
            : ""}
        </span>
        <span id="created_date" className="show_detail">
          {createdDate}
        </span>
      </div>
    );
  }
}

export default Diagnosis_2;

import React, { Component } from "react";
import "./css/Admin_page.css";
import Navigation from "../Navigation.js";
import Dashboard from "./Dashboard.js";
import Diagnosis from "./Diagnosis.js";
import Notice from "./Notice.js";
import Tos from "./Tos.js";
import Faq from "./Faq.js";
import { Link, Route, Routes } from "react-router-dom";
import axios from "axios";

class Admin_page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_DATE: null,
      month_DATE: null,
      today_DAU: null,
      month_MAU: null,
    };
  }

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    this.setState({
      today_DATE: year + "." + month + "." + day,
      month_DATE: year + "." + month,
    });
    this.getToday_DAU();
    this.getMonth_MAU();
  }

  getToday_DAU = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const DAUres = await axios
      .get("/admin/get/Today_DAU")
      .then((res) => {
        let result = 0;
        for (let i = 0; i < res.data.DAU_res.length; i++) {
          if (res.data.DAU_res[i].today == year + " " + month + " " + day) {
            result = res.data.DAU_res[i].DAU;
          }
        }
        this.setState({
          today_DAU: result,
        });
      })
      .catch((error) => {
        alert('DAU를 불러 올 수 없습니다. 관리자에게 문의해 주세요.')
      });
  };

  getMonth_MAU = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const MAUres = await axios.get("/admin/get/Month_MAU")
    .then((res) => {
      let result = 0;
      for (let i = 0; i < res.data.MAU_res.length; i++) {
        if (res.data.MAU_res[i].month == year + " " + month) {
          result = res.data.MAU_res[i].MAU;
        }
      }
      this.setState({
        month_MAU: result,
      });
    })
    .catch((error) => {
      alert('MAU를 불러 올 수 없습니다. 관리자에게 문의해 주세요.')
    })
  };

  render() {
    return (
      <div className="Admin_page">
        <Navigation />
        {/* 중첩 Router 사용 */}
        <div className="seen_board">
          <div className="day">
            {/* DAU */}
            <div className="DAU_wrap" id="Dwrap">
              <span>DAU : </span>
              <span>{this.state.today_DAU}</span>
            </div>

            {/* MAU */}
            <div className="MAU_wrap" id="Dwrap">
              <span>MAU : </span>
              <span>{this.state.month_MAU}</span>
            </div>
            {/* today_DATE */}
          </div>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/Diagnosis/*" element={<Diagnosis />} />
            <Route path="/Notice/*" element={<Notice />} />
            <Route path="/Tos/*" element={<Tos />} />
            <Route path="/Faq/*" element={<Faq />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default Admin_page;

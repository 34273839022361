import React, { useEffect } from "react";
import "./Navigation.css";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBookBookmark } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

const date = new Date();
const year = date.getFullYear();
const month = ("0" + (date.getMonth() + 1)).slice(-2);
const day = ("0" + date.getDate()).slice(-2);
const today_DATE = year + "." + month + "." + day;

function Navigation() {
  const COOKIE_KEY = window.LOGIN_KEY;
  const [, , removeCookie] = useCookies([COOKIE_KEY]);

  const LogOut = () => {
    removeCookie(COOKIE_KEY, { path: "/" });
    window.location.href = "/";
  };

  const move_Notic = (e) => {
    const innerText = e.target.innerText;
    $(".Navigation>.menuBtn").css({
      color: "rgb(70, 70, 70)",
      "font-weight": "normal",
    });
    if (innerText == "메인화면") {
      window.location.href = "/Admin_Page";
    } else if (innerText == "검사결과") {
      window.location.href = "/Admin_Page/Diagnosis";
    } else if (innerText == "공지사항") {
      window.location.href = "/Admin_Page/Notice";
    } else if (innerText == "이용약관") {
      window.location.href = "/Admin_Page/Tos";
    } else if (innerText == "문의사항") {
      window.location.href = "/Admin_Page/Faq";
    }
    e.preventDefault();
  };

  return (
    <div className="Navigation">
      <div className="profile">
        <span>Rokseo_admin</span>
        <span>{today_DATE}</span>
      </div>
      
      <button className="menuBtn" id="menuBtn1" onClick={(e) => move_Notic(e)}>
        <img src="/menu1.svg" />
        <span>메인화면</span>
      </button>
      <button className="menuBtn" id="menuBtn2" onClick={(e) => move_Notic(e)}>
        <img src="/menu2.svg" />
        <span>검사결과</span>
      </button>
      <button className="menuBtn" id="menuBtn3" onClick={(e) => move_Notic(e)}>
        <img src="/menu3.svg" />
        <span>공지사항</span>
      </button>
      <button className="menuBtn" id="menuBtn4" onClick={(e) => move_Notic(e)}>
        <img src="/menu4.svg" />
        <span>이용약관</span>
      </button>
      <button className="menuBtn" id="menuBtn5" onClick={(e) => move_Notic(e)}>
        <img src="/menu5.svg" />
        <span>문의사항</span>
      </button>

      <button className="LogoutBtn" onClick={LogOut}>
        <img src="/logout.svg" />
        <span>Logout</span>
      </button>
    </div>
  );
}

export default Navigation;

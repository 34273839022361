import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login.js";
import Admin_page from "./components/Admin_page.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/Admin_Page/*" element={<Admin_page />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

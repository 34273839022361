import React, { Component } from "react";
import "./css/Service_log_2.css";

class Service_log_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="Service_log_2">
        <span>{this.props.log_type}</span>
        <span>{this.props.user_email}</span>
        <span></span>
        <span>{this.props.time}</span>
      </div>
    );
  }
}

export default Service_log_2;

import React, { Component } from "react";
import "./css/Faq_2.css";

class Faq_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  move_Faq_detail = (e) => {
    window.location.href = "/Admin_Page/Faq/Faq_detail/num=" + this.props.idx;
  };

  render() {
    const createdDate = this.props.created_date.split("T")[0];
    return (
      <div className="Faq_2">
        <span>
          <input type="checkbox" id={this.props.idx} name="faq_check" />
        </span>
        <span className="idxDiv" style={{ display: "none" }}>
          {this.props.idx}
        </span>
        <span onClick={(e) => this.move_Faq_detail(e)}>{this.props.title}</span>
        <span></span>
        <span onClick={(e) => this.move_Faq_detail(e)}>{createdDate}</span>
        {/* <div>{this.props.contents}</div> */}
      </div>
    );
  }
}

export default Faq_2;

import React, { Component } from "react";
import "./css/Dashboard.css";
import $ from "jquery";
import axios from "axios";
import Service_log_1 from "./Service_log_1.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logList: [],
    };
  }

  componentDidMount() {
    $(".Navigation #menuBtn1 img").attr("src", "/menu1_green.svg");
    $(".Navigation #menuBtn1").css({
      color: "#368552",
      "background-color": "#5ab3795d",
      "font-weight": "bold",
    });

    this.getService_log();
  }

  getService_log = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const logDay = year + " " + month;
    const Log = await axios
      .get("/admin/get/service_log" + logDay)
      .then((res) => {
        this.setState({
          logList: res.data.service_res,
        });
      })
      .catch((error) => {
        alert(
          "최근 한달간 접속한 유저의 정보를 불러오지 못했습니다. 관리자에게 문의해 주세요."
        );
      });
  };

  render() {
    return (
      <div className="Dashboard">
        <div className="title">
          최근 한달간 접속한 유저
          <span>한 페이지당 보이는 개수 : 10</span>
        </div>
        <div className="list_wrap">
          <Service_log_1 logList={this.state.logList} />
        </div>
      </div>
    );
  }
}

export default Dashboard;

import React, { Component } from "react";
import "./Login.css";

var backControl = function (callbackFunction) {
  //window 내 mouse 위치 여부를 체크하기 위한 변수
  window.innerDoc = false;
  window.addEventListener("mouseover", function (event) {
    window.innerDoc = true;
  });
  window.addEventListener("mouseout", function (event) {
    window.innerDoc = false;
  });
  window.history.pushState(
    { page: "first" },
    document.title,
    window.location.pathname + "#first"
  );
  window.addEventListener("popstate", function (event) {
    window.history.pushState(
      { page: "historyChanged" },
      document.title,
      window.location.pathname + "#changed"
    );

    if (!window.innerDoc) {
      callbackFunction();
    }
  });
};
var callbackFunction = function () {
  //전 페이지로 이동이 불가능 합니다.
};
backControl(callbackFunction);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminEmail: null,
      adminPassword: null,
    };
  }

  Login_Email(e) {
    this.setState({
      adminEmail: e.target.value,
    });
  }
  Login_Pass(e) {
    this.setState({
      adminPassword: e.target.value,
    });
  }

  handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      this.LoginBtn(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  LoginBtn = () => {
    const adminEmail = this.state.adminEmail;
    const adminPassword = this.state.adminPassword;
    if (adminEmail === "admin@rokseo" || adminEmail === "admin@rokseo_1") {
      if (adminPassword === "rokseo!1357") {
        window.location.href = "/Admin_Page";
      } else {
        alert("비밀번호가 틀립니다.");
      }
    } else {
      alert("등록된 관리자 계정이 아닙니다.");
    }
  };

  render() {
    return (
      <div className="Login">
        <img src="/login.png" />
        <form className="Login_form">
          <input
            type="email"
            placeholder="admin@Email"
            id="email"
            onChange={(e) => this.Login_Email(e)}
            onKeyPress={(e) => this.handleOnKeyPress(e)}
          />
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={(e) => this.Login_Pass(e)}
            onKeyPress={(e) => this.handleOnKeyPress(e)}
          />
          <input
            type="button"
            value="Log In"
            id="submit"
            onClick={this.LoginBtn}
          />
        </form>
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import "./css/Service_log_1.css";
import Service_log_2 from "./Service_log_2.js";

class Service_log_1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
    this.perPage = 10; // 한 페이지에 보여질 항목 수
  }

  getCurrentPageData() {
    const { logList } = this.props;
    const { currentPage } = this.state;
    const startIndex = (currentPage - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    return logList.slice(startIndex, endIndex);
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleNextPage = () => {
    const { logList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(logList.length / this.perPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
  };

  renderPagination() {
    const { logList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(logList.length / this.perPage);
  
    const pageNumbers = [];
    const maxPageNumber = Math.min(totalPages, 10); // 최대 10개의 페이지 번호만 표시
  
    // 현재 페이지가 10 이하인 경우
    if (currentPage <= 10) {
      for (let i = 1; i <= maxPageNumber; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    } else {
      // 현재 페이지가 10 이상인 경우
      const startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
      const endPage = Math.min(startPage + 9, totalPages);
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    }
  
    return <div className="pagination_num">{pageNumbers}</div>;
  }

  render() {
    const currentPageData = this.getCurrentPageData();
    const logList = currentPageData.map((data, index) => (
      <Service_log_2
        key={index}
        time={data.time}
        user_email={data.user_email}
        log_type={data.log_type}
      />
    ));

    return (
      <div className="Service_log_1">
        <div className="boxTitle">
          <span>Log Type</span>
          <span>User Email</span>
          <span></span>
          <span>Connection time</span>
        </div>
        <div className="logList_box">{logList}</div>
        <div className="pagination">
          <button id="prev" onClick={this.handlePreviousPage}>
            <img src="/left.svg" alt="Previous" />
          </button>
          {this.renderPagination()}
          <button id="next" onClick={this.handleNextPage}>
            <img src="/right.svg" alt="Next" />
          </button>
        </div>
      </div>
    );
  }
}

export default Service_log_1;

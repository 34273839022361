import React, { Component } from "react";
import "./css/Diagnosis.css";
import Diagnosis_List from "./Diagnosis_1.js";
import axios from "axios";
import $, { error } from "jquery";
import { Route, Routes } from "react-router-dom";
import Calendar from "./Calender";
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";

const workbook = new ExcelJS.Workbook();
const worksheet = workbook.addWorksheet("Data");
const excelFileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const excelFileExtension = ".xlsx";
const excelFileName = "검사결과_리스트";

class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_DATE: null,
      diagnosisList: [],
      idx: null,
      user_idx: null,
      diagnosisList_detail: [],
      diagnosisList_detail_date: null,
      defaulSelect: "검사결과날짜",
      set_textSearch: null,
      getDate: null,
      currentPage: 1,

      guaiac_text: null, //잠혈
      bilirubin_text: null, //빌리루빈
      urobilinogen_text: null, //우로빌리노겐
      ketone_text: null, //케톤체
      protein_text: null, //단백질
      nitrituria_text: null, //아질산염
      glucose_text: null, //포도당
      ph_text: null, //ph
      gravity_text: null, //비중
      leukocyte_text: null, //백혈구

      guaiac_score: null, //잠혈 점수
      bilirubin_score: null, //빌리루빈 점수
      urobilinogen_score: null, //우로빌리노겐 점수
      ketone_score: null, //케톤체 점수
      protein_score: null, //단백질 점수
      nitrituria_score: null, //아질산염 점수
      glucose_score: null, //포도당 점수
      ph_score: null, //ph 점수
      gravity_score: null, //비중 점수
      leukocyte_score: null, //백혈구 점수

      user_name: null,
      user_sex: null,
      user_brith: null,
      user_age: null,
      user_email: null,
      user_height: null,
      user_weight: null,
      user_drinking: null,
      user_smoking: null,
      user_chronic_disease: null,
      this_year: null,
    };
  }

  excelDownload_start = async () => {
    const checkedIds = [];
    const checkboxes = document.getElementsByName("diagnosis_check");

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedIds.push(checkbox.id);
      }
    });
    if (checkedIds.length === 0) {
      window.alert("다운로드할 검사결과를 선택해주세요.");
      return;
    }
    if (checkedIds.length > 10) {
      window.alert("10개 이상의 데이터는 다운로드 할 수 없습니다!");
      return;
    }

    const res = await axios
      .get("/admin/get/check_download_diagnosis" + checkedIds)
      .then((res) => {
        console.log(res.data.diagnosis_res)
        if(res.data.diagnosis_res.length > 0) {
          const excelDownload = this.excelDownload(res.data.diagnosis_res);
        setTimeout(() => {
          this.excel_export(excelDownload);
        }, 500);
        }
      })
      .catch((error) => {
        alert("다운로드할 데이터를 불러오지 못했습니다!");
      });
  };
  base64 = async (e) => {
    const img_base = await fetch(e)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      });
    return img_base;
  };

  excelDownload = async (get_data) => {
    console.log(get_data)
    const data = [];
    const datalist = get_data;

    for (let i = 0; i < datalist.length; i++) {
      data.push(datalist[i]);
    }
    worksheet.columns = [
      { header: "검사자 비색표", key: "img" },
      { header: "항목", key: "category" },
      { header: "비색표 색상", key: "color" },
      { header: "", key: "" },
      { header: "음성(-)", key: "-" },
      { header: "±", key: "±" },
      { header: "+", key: "+" },
      { header: "++", key: "++" },
      { header: "+++", key: "+++" },
      { header: "++++", key: "++++" },
      { header: "결과", key: "result" },
      { header: "점수", key: "score" },
    ];

    // header
    const firstRow = worksheet.getRow(1);
    firstRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E0DBD3" },
    };
    firstRow.font = {
      name: "Arial", // 글꼴 이름

      bold: true,
      size: 12,
    };
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 1,
        topLeftCell: "A2",
        activeCell: "A2",
      },
    ];

    // C-I 짝수 행의 높이를 100px로 설정
    for (let i = 2; i <= data.length * 20; i += 2) {
      const row = worksheet.getRow(i);
      row.height = 30;
    }
    // C-I 홀수 행의 높이를 100px로 설정
    for (let i = 3; i <= data.length * 20; i += 2) {
      const row = worksheet.getRow(i);
      row.height = 20;
    }
    // A행부터 K행까지의 너비 값
    const columnWidths = [50, 15, 23, 1, 23, 23, 23, 23, 23, 23, 12, 12];

    // A행부터 L행까지의 너비 설정
    for (let i = 1; i <= 12; i++) {
      const column = worksheet.getColumn(i);
      column.width = columnWidths[i - 1];
    }

    const merges = [];
    for (let i = 0; i < data.length; i++) {
      const merge = {
        start: { row: i * 20 + 2, col: 1 },
        end: { row: (i + 1) * 20 + 1, col: 1 },
      };
      merges.push(merge);

      for (let j = 0; j < 10; j++) {
        const merge2 = {
          start: { row: j * 2 + 2 + i * 20, col: 2 },
          end: { row: j * 2 + 3 + i * 20, col: 2 },
        };
        merges.push(merge2);
        const merge3 = {
          start: { row: j * 2 + 2 + i * 20, col: 11 },
          end: { row: j * 2 + 3 + i * 20, col: 11 },
        };
        merges.push(merge3);
        const merge4 = {
          start: { row: j * 2 + 2 + i * 20, col: 12 },
          end: { row: j * 2 + 3 + i * 20, col: 12 },
        };
        merges.push(merge4);
      }
    }
    merges.forEach((merge, index) => {
      worksheet.mergeCells(
        merge.start.row,
        merge.start.col,
        merge.end.row,
        merge.end.col
      );
    });

    const chunkSize = 20; // Number of rows to merge in one chunk

    for (let i = 0; i < data.length; i++) {
      const startRow = i * chunkSize + 2;
      const endRow = (i + 1) * chunkSize + 1;

      // Merge the first column (column 1)
      const merge1 = {
        start: { row: startRow, col: 1 },
        end: { row: endRow, col: 1 },
      };
      merges.push(merge1);

      // Merge column 2 and column 11 and column 12
      for (let j = 0; j < 10; j++) {
        const startRow2 = j * 2 + 2 + i * chunkSize;
        const endRow2 = j * 2 + 3 + i * chunkSize;

        const merge2 = {
          start: { row: startRow2, col: 2 },
          end: { row: endRow2, col: 2 },
        };
        const merge3 = {
          start: { row: startRow2, col: 11 },
          end: { row: endRow2, col: 11 },
        };
        const merge4 = {
          start: { row: startRow2, col: 12 },
          end: { row: endRow2, col: 12 },
        };

        merges.push(merge2, merge3, merge4);
      }
    }

    merges.forEach((merge, index) => {
      if (!worksheet.getCell(merge.start.row, merge.start.col).isMerged) {
        worksheet.mergeCells(
          merge.start.row,
          merge.start.col,
          merge.end.row,
          merge.end.col
        );
      }
    });

    // 카테고리
    const category = [
      "잠혈",
      "빌리루빈",
      "우로빌리노겐",
      "케톤체",
      "단백질",
      "아질산염",
      "포도당",
      "PH",
      "비중",
      "백혈구",
    ];
    data.forEach((item, index) => {
      category.forEach((value, index2) => {
        const rowNumber = 2 + index2 * 2 + index * 20;
        worksheet.getCell(`B${rowNumber}`).value = value;
      });
    });

    //고정 - 유사도 수치 →
    const text = [
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
      "유사도 수치 →",
    ];
    data.forEach((item, index) => {
      text.forEach((value, index2) => {
        const rowNumber = 3 + index2 * 2 + index * 20;
        worksheet.getCell(`C${rowNumber}`).value = value;
      });
    });

    const columnD = worksheet.getColumn("D");
    columnD.eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // 화이트(흰색) 색상
      };
    });

    let rowIndex = 2;

    data.forEach((data, index) => {
      // 비색표 색상
      const cellGuaiac = worksheet.getCell(`C${rowIndex + index * 20}`);
      cellGuaiac.value = data.guaiaccolor;
      const hexGuaiac = this.rgbToHex(data.guaiaccolor);
      cellGuaiac.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexGuaiac}` },
      };
      const cellBilirubin = worksheet.getCell(`C${rowIndex + 2 + index * 20}`);
      cellBilirubin.value = data.bilirubincolor;
      const hexBilirubin = this.rgbToHex(data.bilirubincolor);
      cellBilirubin.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexBilirubin}` },
      };

      const cellUrobilinogen = worksheet.getCell(
        `C${rowIndex + 4 + index * 20}`
      );
      cellUrobilinogen.value = data.urobilinogencolor;
      const hexUrobilinogen = this.rgbToHex(data.urobilinogencolor);
      cellUrobilinogen.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexUrobilinogen}` },
      };

      const cellKetone = worksheet.getCell(`C${rowIndex + 6 + index * 20}`);
      cellKetone.value = data.ketonecolor;
      const hexKetone = this.rgbToHex(data.ketonecolor);
      cellKetone.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexKetone}` },
      };

      const cellProtein = worksheet.getCell(`C${rowIndex + 8 + index * 20}`);
      cellProtein.value = data.proteincolor;
      const hexProtein = this.rgbToHex(data.proteincolor);
      cellProtein.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexProtein}` },
      };

      const cellNitrituria = worksheet.getCell(
        `C${rowIndex + 10 + index * 20}`
      );
      cellNitrituria.value = data.nitrituriacolor;
      const hexNitrituria = this.rgbToHex(data.nitrituriacolor);
      cellNitrituria.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexNitrituria}` },
      };

      const cellGlucose = worksheet.getCell(`C${rowIndex + 12 + index * 20}`);
      cellGlucose.value = data.glucosecolor;
      const hexGlucose = this.rgbToHex(data.glucosecolor);
      cellGlucose.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexGlucose}` },
      };

      const cellpH = worksheet.getCell(`C${rowIndex + 14 + index * 20}`);
      cellpH.value = data.phcolor;
      const hexpH = this.rgbToHex(data.phcolor);
      cellpH.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexpH}` },
      };

      const cellGravity = worksheet.getCell(`C${rowIndex + 16 + index * 20}`);
      cellGravity.value = data.gravitycolor;
      const hexGravity = this.rgbToHex(data.gravitycolor);
      cellGravity.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexGravity}` },
      };

      const cellLeukocyte = worksheet.getCell(`C${rowIndex + 18 + index * 20}`);
      cellLeukocyte.value = data.leukocytecolor;
      const hexLeukocyte = this.rgbToHex(data.leukocytecolor);
      cellLeukocyte.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hexLeukocyte}` },
      };

      // 음성(-)
      const cell_guaiacArr0 = worksheet.getCell(`E${rowIndex + index * 20}`);
      cell_guaiacArr0.value = data.guaiacArr0;
      const hex_guaiacArr0 = this.rgbToHex(data.guaiacArr0);
      cell_guaiacArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_guaiacArr0}` },
      };
      const cell_bilirubinArr0 = worksheet.getCell(
        `E${rowIndex + 2 + index * 20}`
      );
      cell_bilirubinArr0.value = data.bilirubinArr0;
      const hex_bilirubinArr0 = this.rgbToHex(data.bilirubinArr0);
      cell_bilirubinArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_bilirubinArr0}` },
      };

      const cell_urobilinogenArr0 = worksheet.getCell(
        `E${rowIndex + 4 + index * 20}`
      );
      cell_urobilinogenArr0.value = data.urobilinogenArr0;
      const hex_urobilinogenArr0 = this.rgbToHex(data.urobilinogenArr0);
      cell_urobilinogenArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_urobilinogenArr0}` },
      };

      const cell_ketoneArr0 = worksheet.getCell(
        `E${rowIndex + 6 + index * 20}`
      );
      cell_ketoneArr0.value = data.ketoneArr0;
      const hex_ketoneArr0 = this.rgbToHex(data.ketoneArr0);
      cell_ketoneArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_ketoneArr0}` },
      };

      const cell_proteinArr0 = worksheet.getCell(
        `E${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr0.value = data.proteinArr0;
      const hex_proteinArr0 = this.rgbToHex(data.proteinArr0);
      cell_proteinArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr0}` },
      };

      const cell_nitrituriaArr0 = worksheet.getCell(
        `E${rowIndex + 10 + index * 20}`
      );
      cell_nitrituriaArr0.value = data.nitrituriaArr0;
      const hex_nitrituriaArr0 = this.rgbToHex(data.nitrituriaArr0);
      cell_nitrituriaArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_nitrituriaArr0}` },
      };

      const cell_glucoseArr0 = worksheet.getCell(
        `E${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr0.value = data.glucoseArr0;
      const hex_glucoseArr0 = this.rgbToHex(data.glucoseArr0);
      cell_glucoseArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr0}` },
      };

      const cell_phArr0 = worksheet.getCell(`E${rowIndex + 14 + index * 20}`);
      cell_phArr0.value = data.phArr0;
      const hex_phArr0 = this.rgbToHex(data.phArr0);
      cell_phArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_phArr0}` },
      };

      const cell_gravityArr0 = worksheet.getCell(
        `E${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr0.value = data.gravityArr0;
      const hex_gravityArr0 = this.rgbToHex(data.gravityArr0);
      cell_gravityArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr0}` },
      };

      const cell_leukocyteArr0 = worksheet.getCell(
        `E${rowIndex + 18 + index * 20}`
      );
      cell_leukocyteArr0.value = data.leukocyteArr0;
      const hex_leukocyteArr0 = this.rgbToHex(data.leukocyteArr0);
      cell_leukocyteArr0.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_leukocyteArr0}` },
      };

      worksheet.getCell(`E${rowIndex + 1 + index * 20}`).value =
        data.guaiacArrre0;
      worksheet.getCell(`E${rowIndex + 3 + index * 20}`).value =
        data.bilirubinArrre0;
      worksheet.getCell(`E${rowIndex + 5 + index * 20}`).value =
        data.urobilinogenArrre0;
      worksheet.getCell(`E${rowIndex + 7 + index * 20}`).value =
        data.ketoneArrre0;
      worksheet.getCell(`E${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre0;
      worksheet.getCell(`E${rowIndex + 11 + index * 20}`).value =
        data.nitrituriaArrre0;
      worksheet.getCell(`E${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre0;
      worksheet.getCell(`E${rowIndex + 15 + index * 20}`).value = data.phArrre0;
      worksheet.getCell(`E${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre0;
      worksheet.getCell(`E${rowIndex + 19 + index * 20}`).value =
        data.leukocyteArrre0;

      // ±
      const cell_ketoneArr1 = worksheet.getCell(
        `F${rowIndex + 6 + index * 20}`
      );
      cell_ketoneArr1.value = data.ketoneArr1;
      const hex_ketoneArr1 = this.rgbToHex(data.ketoneArr1);
      cell_ketoneArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_ketoneArr1}` },
      };

      const cell_proteinArr1 = worksheet.getCell(
        `F${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr1.value = data.proteinArr1;
      const hex_proteinArr1 = this.rgbToHex(data.proteinArr1);
      cell_proteinArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr1}` },
      };
      const cell_glucoseArr1 = worksheet.getCell(
        `F${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr1.value = data.glucoseArr1;
      const hex_glucoseArr1 = this.rgbToHex(data.glucoseArr1);
      cell_glucoseArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr1}` },
      };

      const cell_phArr1 = worksheet.getCell(`F${rowIndex + 14 + index * 20}`);
      cell_phArr1.value = data.phArr1;
      const hex_phArr1 = this.rgbToHex(data.phArr1);
      cell_phArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_phArr1}` },
      };

      const cell_gravityArr1 = worksheet.getCell(
        `F${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr1.value = data.gravityArr1;
      const hex_gravityArr1 = this.rgbToHex(data.gravityArr1);
      cell_gravityArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr1}` },
      };

      worksheet.getCell(`F${rowIndex + 7 + index * 20}`).value =
        data.ketoneArrre1;
      worksheet.getCell(`F${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre1;
      worksheet.getCell(`F${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre1;
      worksheet.getCell(`F${rowIndex + 15 + index * 20}`).value = data.phArrre1;
      worksheet.getCell(`F${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre1;
      worksheet.getCell(`F${rowIndex + 19 + index * 20}`).value = "";

      // +
      const cell_guaiacArr1 = worksheet.getCell(`G${rowIndex + index * 20}`);
      cell_guaiacArr1.value = data.guaiacArr1;
      const hex_guaiacArr1 = this.rgbToHex(data.guaiacArr1);
      cell_guaiacArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_guaiacArr1}` },
      };
      const cell_bilirubinArr1 = worksheet.getCell(
        `G${rowIndex + 2 + index * 20}`
      );
      cell_bilirubinArr1.value = data.bilirubinArr1;
      const hex_bilirubinArr1 = this.rgbToHex(data.bilirubinArr1);
      cell_bilirubinArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_bilirubinArr1}` },
      };

      const cell_urobilinogenArr1 = worksheet.getCell(
        `G${rowIndex + 4 + index * 20}`
      );
      cell_urobilinogenArr1.value = data.urobilinogenArr1;
      const hex_urobilinogenArr1 = this.rgbToHex(data.urobilinogenArr1);
      cell_urobilinogenArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_urobilinogenArr1}` },
      };

      const cell_ketoneArr2 = worksheet.getCell(
        `G${rowIndex + 6 + index * 20}`
      );
      cell_ketoneArr2.value = data.ketoneArr2;
      const hex_ketoneArr2 = this.rgbToHex(data.ketoneArr2);
      cell_ketoneArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_ketoneArr2}` },
      };

      const cell_proteinArr2 = worksheet.getCell(
        `G${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr2.value = data.proteinArr2;
      const hex_proteinArr2 = this.rgbToHex(data.proteinArr2);
      cell_proteinArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr2}` },
      };

      const cell_nitrituriaArr1 = worksheet.getCell(
        `G${rowIndex + 10 + index * 20}`
      );
      cell_nitrituriaArr1.value = data.nitrituriaArr1;
      const hex_nitrituriaArr1 = this.rgbToHex(data.nitrituriaArr1);
      cell_nitrituriaArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_nitrituriaArr1}` },
      };

      const cell_glucoseArr2 = worksheet.getCell(
        `G${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr2.value = data.glucoseArr2;
      const hex_glucoseArr2 = this.rgbToHex(data.glucoseArr2);
      cell_glucoseArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr2}` },
      };

      const cell_phArr2 = worksheet.getCell(`G${rowIndex + 14 + index * 20}`);
      cell_phArr2.value = data.phArr2;
      const hex_phArr2 = this.rgbToHex(data.phArr2);
      cell_phArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_phArr2}` },
      };

      const cell_gravityArr2 = worksheet.getCell(
        `G${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr2.value = data.gravityArr2;
      const hex_gravityArr2 = this.rgbToHex(data.gravityArr2);
      cell_gravityArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr2}` },
      };

      const cell_leukocyteArr1 = worksheet.getCell(
        `G${rowIndex + 18 + index * 20}`
      );
      cell_leukocyteArr1.value = data.leukocyteArr1;
      const hex_leukocyteArr1 = this.rgbToHex(data.leukocyteArr1);
      cell_leukocyteArr1.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_leukocyteArr1}` },
      };

      worksheet.getCell(`G${rowIndex + 1 + index * 20}`).value =
        data.guaiacArrre1;
      worksheet.getCell(`G${rowIndex + 3 + index * 20}`).value =
        data.bilirubinArrre1;
      worksheet.getCell(`G${rowIndex + 5 + index * 20}`).value =
        data.urobilinogenArrre1;
      worksheet.getCell(`G${rowIndex + 7 + index * 20}`).value =
        data.ketoneArrre2;
      worksheet.getCell(`G${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre2;
      worksheet.getCell(`G${rowIndex + 11 + index * 20}`).value =
        data.nitrituriaArrre1;
      worksheet.getCell(`G${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre2;
      worksheet.getCell(`G${rowIndex + 15 + index * 20}`).value = data.phArrre2;
      worksheet.getCell(`G${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre2;
      worksheet.getCell(`G${rowIndex + 19 + index * 20}`).value =
        data.leukocyteArrre1;

      // ++
      const cell_guaiacArr2 = worksheet.getCell(`H${rowIndex + index * 20}`);
      cell_guaiacArr2.value = data.guaiacArr2;
      const hex_guaiacArr2 = this.rgbToHex(data.guaiacArr2);
      cell_guaiacArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_guaiacArr2}` },
      };

      const cell_bilirubinArr2 = worksheet.getCell(
        `H${rowIndex + 2 + index * 20}`
      );
      cell_bilirubinArr2.value = data.bilirubinArr2;
      const hex_bilirubinArr2 = this.rgbToHex(data.bilirubinArr2);
      cell_bilirubinArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_bilirubinArr2}` },
      };

      const cell_urobilinogenArr2 = worksheet.getCell(
        `H${rowIndex + 4 + index * 20}`
      );
      cell_urobilinogenArr2.value = data.urobilinogenArr2;
      const hex_urobilinogenArr2 = this.rgbToHex(data.urobilinogenArr2);
      cell_urobilinogenArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_urobilinogenArr2}` },
      };

      const cell_ketoneArr3 = worksheet.getCell(
        `H${rowIndex + 6 + index * 20}`
      );
      cell_ketoneArr3.value = data.ketoneArr3;
      const hex_ketoneArr3 = this.rgbToHex(data.ketoneArr3);
      cell_ketoneArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_ketoneArr3}` },
      };

      const cell_proteinArr3 = worksheet.getCell(
        `H${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr3.value = data.proteinArr3;
      const hex_proteinArr3 = this.rgbToHex(data.proteinArr3);
      cell_proteinArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr3}` },
      };

      const cell_glucoseArr3 = worksheet.getCell(
        `H${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr3.value = data.glucoseArr3;
      const hex_glucoseArr3 = this.rgbToHex(data.glucoseArr3);
      cell_glucoseArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr3}` },
      };

      const cell_phArr3 = worksheet.getCell(`H${rowIndex + 14 + index * 20}`);
      cell_phArr3.value = data.phArr3;
      const hex_phArr3 = this.rgbToHex(data.phArr3);
      cell_phArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_phArr3}` },
      };

      const cell_gravityArr3 = worksheet.getCell(
        `H${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr3.value = data.gravityArr3;
      const hex_gravityArr3 = this.rgbToHex(data.gravityArr3);
      cell_gravityArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr3}` },
      };

      const cell_leukocyteArr2 = worksheet.getCell(
        `H${rowIndex + 18 + index * 20}`
      );
      cell_leukocyteArr2.value = data.leukocyteArr2;
      const hex_leukocyteArr2 = this.rgbToHex(data.leukocyteArr2);
      cell_leukocyteArr2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_leukocyteArr2}` },
      };
      worksheet.getCell(`H${rowIndex + 1 + index * 20}`).value =
        data.guaiacArrre2;
      worksheet.getCell(`H${rowIndex + 3 + index * 20}`).value =
        data.bilirubinArrre2;
      worksheet.getCell(`H${rowIndex + 5 + index * 20}`).value =
        data.urobilinogenArrre2;
      worksheet.getCell(`H${rowIndex + 7 + index * 20}`).value =
        data.ketoneArrre3;
      worksheet.getCell(`H${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre3;
      worksheet.getCell(`H${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre3;
      worksheet.getCell(`H${rowIndex + 15 + index * 20}`).value = data.phArrre3;
      worksheet.getCell(`H${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre3;
      worksheet.getCell(`H${rowIndex + 19 + index * 20}`).value =
        data.leukocyteArrre2;

      // +++
      const cell_guaiacArr3 = worksheet.getCell(`I${rowIndex + index * 20}`);
      cell_guaiacArr3.value = data.guaiacArr3;
      const hex_guaiacArr3 = this.rgbToHex(data.guaiacArr3);
      cell_guaiacArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_guaiacArr3}` },
      };
      const cell_bilirubinArr3 = worksheet.getCell(
        `I${rowIndex + 2 + index * 20}`
      );
      cell_bilirubinArr3.value = data.bilirubinArr3;
      const hex_bilirubinArr3 = this.rgbToHex(data.bilirubinArr3);
      cell_bilirubinArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_bilirubinArr3}` },
      };
      const cell_urobilinogenArr3 = worksheet.getCell(
        `I${rowIndex + 4 + index * 20}`
      );
      cell_urobilinogenArr3.value = data.urobilinogenArr3;
      const hex_urobilinogenArr3 = this.rgbToHex(data.urobilinogenArr3);
      cell_urobilinogenArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_urobilinogenArr3}` },
      };
      // const cell_ketoneArr4 = worksheet.getCell(
      //   `I${rowIndex + 6 + index * 20}`
      // );
      // cell_ketoneArr4.value = data.ketoneArr3;
      // const hex_ketoneArr4 = this.rgbToHex(data.ketoneArr3);
      // cell_ketoneArr4.fill = {
      //   type: "pattern",
      //   pattern: "solid",
      //   fgColor: { argb: `FF${hex_ketoneArr4}` },
      // };
      const cell_proteinArr4 = worksheet.getCell(
        `I${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr4.value = data.proteinArr4;
      const hex_proteinArr4 = this.rgbToHex(data.proteinArr4);
      cell_proteinArr4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr4}` },
      };
      const cell_glucoseArr4 = worksheet.getCell(
        `I${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr4.value = data.glucoseArr4;
      const hex_glucoseArr4 = this.rgbToHex(data.glucoseArr4);
      cell_glucoseArr4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr4}` },
      };
      const cell_phArr4 = worksheet.getCell(`I${rowIndex + 14 + index * 20}`);
      cell_phArr4.value = data.phArr4;
      const hex_phArr4 = this.rgbToHex(data.phArr4);
      cell_phArr4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_phArr4}` },
      };
      const cell_gravityArr4 = worksheet.getCell(
        `I${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr4.value = data.gravityArr4;
      const hex_gravityArr4 = this.rgbToHex(data.gravityArr4);
      cell_gravityArr4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr4}` },
      };
      const cell_leukocyteArr3 = worksheet.getCell(
        `I${rowIndex + 18 + index * 20}`
      );
      cell_leukocyteArr3.value = data.leukocyteArr3;
      const hex_leukocyteArr3 = this.rgbToHex(data.leukocyteArr3);
      cell_leukocyteArr3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_leukocyteArr3}` },
      };
      worksheet.getCell(`I${rowIndex + 1 + index * 20}`).value =
        data.guaiacArrre3;
      worksheet.getCell(`I${rowIndex + 3 + index * 20}`).value =
        data.bilirubinArrre3;
      worksheet.getCell(`I${rowIndex + 5 + index * 20}`).value =
        data.urobilinogenArrre3;
      // worksheet.getCell(`I${rowIndex + 7 + index * 20}`).value =
      //   data.ketoneArrre4;
      worksheet.getCell(`I${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre4;
      worksheet.getCell(`I${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre4;
      worksheet.getCell(`I${rowIndex + 15 + index * 20}`).value = data.phArrre4;
      worksheet.getCell(`I${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre4;
      worksheet.getCell(`I${rowIndex + 19 + index * 20}`).value =
        data.leukocyteArrre3;

      // ++++
      const cell_urobilinogenArr4 = worksheet.getCell(
        `J${rowIndex + 4 + index * 20}`
      );
      cell_urobilinogenArr4.value = data.urobilinogenArr4;
      const hex_urobilinogenArr4 = this.rgbToHex(data.urobilinogenArr4);
      cell_urobilinogenArr4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_urobilinogenArr4}` },
      };
      const cell_proteinArr5 = worksheet.getCell(
        `J${rowIndex + 8 + index * 20}`
      );
      cell_proteinArr5.value = data.proteinArr5;
      const hex_proteinArr5 = this.rgbToHex(data.proteinArr5);
      cell_proteinArr5.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_proteinArr5}` },
      };
      const cell_glucoseArr5 = worksheet.getCell(
        `J${rowIndex + 12 + index * 20}`
      );
      cell_glucoseArr5.value = data.glucoseArr5;
      const hex_glucoseArr5 = this.rgbToHex(data.glucoseArr5);
      cell_glucoseArr5.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_glucoseArr5}` },
      };
      const cell_gravityArr5 = worksheet.getCell(
        `J${rowIndex + 16 + index * 20}`
      );
      cell_gravityArr5.value = data.gravityArr5;
      const hex_gravityArr5 = this.rgbToHex(data.gravityArr5);
      cell_gravityArr5.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${hex_gravityArr5}` },
      };
      worksheet.getCell(`J${rowIndex + 5 + index * 20}`).value =
        data.urobilinogenArrre4;
      worksheet.getCell(`J${rowIndex + 9 + index * 20}`).value =
        data.proteinArrre5;
      worksheet.getCell(`J${rowIndex + 13 + index * 20}`).value =
        data.glucoseArrre5;
      worksheet.getCell(`J${rowIndex + 17 + index * 20}`).value =
        data.gravityArrre5;
      worksheet.getCell(`J${rowIndex + 19 + index * 20}`).value = "";

      // 잠혈 결과
      const guaiac_result_text = [];
      const guaiac_result_score = [];
      const guaiac_values = [
        data.guaiacArrre0,
        data.guaiacArrre1,
        data.guaiacArrre2,
        data.guaiacArrre3,
      ];
      var guaiac_minValue = Math.min(...guaiac_values);
      var guaiac_minIndex = guaiac_values.findIndex(
        (value) => value == guaiac_minValue
      );
      var guaiac_text = null;
      var guaiac_score = null;
      if (guaiac_minIndex == 0) {
        guaiac_text = "좋음";
        guaiac_score = 100;
        guaiac_result_text.push(guaiac_text);
        guaiac_result_score.push(guaiac_score);
      } else if (guaiac_minIndex == 1) {
        guaiac_text = "적절";
        guaiac_score = 75;
        guaiac_result_text.push(guaiac_text);
        guaiac_result_score.push(guaiac_score);
      } else if (guaiac_minIndex == 2) {
        guaiac_text = "주의";
        guaiac_score = 50;
        guaiac_result_text.push(guaiac_text);
        guaiac_result_score.push(guaiac_score);
      } else if (guaiac_minIndex == 3) {
        guaiac_text = "경고";
        guaiac_score = 25;
        guaiac_result_text.push(guaiac_text);
        guaiac_result_score.push(guaiac_score);
      } else {
        guaiac_text = "X";
        guaiac_score = 0;
        guaiac_result_text.push(guaiac_text);
        guaiac_result_score.push(guaiac_score);
      }
      worksheet.getCell(`K${rowIndex + index * 20}`).value =
        guaiac_result_text[0];
      worksheet.getCell(`L${rowIndex + index * 20}`).value =
        guaiac_result_score[0];

      // 빌리루빈 결과
      const bilirubin_result_text = [];
      const bilirubin_result_score = [];
      const bilirubin_values = [
        data.bilirubinArrre0,
        data.bilirubinArrre1,
        data.bilirubinArrre2,
        data.bilirubinArrre3,
      ];
      var bilirubin_minValue = Math.min(...bilirubin_values);
      var bilirubin_minIndex = bilirubin_values.findIndex(
        (value) => value == bilirubin_minValue
      );
      var bilirubin_text = null;
      var bilirubin_score = null;
      if (bilirubin_minIndex == 0) {
        bilirubin_text = "좋음";
        bilirubin_score = 100;
        bilirubin_result_text.push(bilirubin_text);
        bilirubin_result_score.push(bilirubin_score);
      } else if (bilirubin_minIndex == 1) {
        bilirubin_text = "적절";
        bilirubin_score = 75;
        bilirubin_result_text.push(bilirubin_text);
        bilirubin_result_score.push(bilirubin_score);
      } else if (bilirubin_minIndex == 2) {
        bilirubin_text = "주의";
        bilirubin_score = 50;
        bilirubin_result_text.push(bilirubin_text);
        bilirubin_result_score.push(bilirubin_score);
      } else if (bilirubin_minIndex == 3) {
        bilirubin_text = "경고";
        bilirubin_score = 25;
        bilirubin_result_text.push(bilirubin_text);
        bilirubin_result_score.push(bilirubin_score);
      } else {
        bilirubin_text = "X";
        bilirubin_score = 0;
        bilirubin_result_text.push(bilirubin_text);
        bilirubin_result_score.push(bilirubin_score);
      }

      worksheet.getCell(`K${rowIndex + 2 + index * 20}`).value =
        bilirubin_result_text[0];
      worksheet.getCell(`L${rowIndex + 2 + index * 20}`).value =
        bilirubin_result_score[0];

      // 우로빌리노겐 결과
      const urobilinogen_result_text = [];
      const urobilinogen_result_score = [];
      const urobilinogen_values = [
        data.urobilinogenArrre0,
        data.urobilinogenArrre1,
        data.urobilinogenArrre2,
        data.urobilinogenArrre3,
        data.urobilinogenArrre4,
      ];
      var urobilinogen_minValue = Math.min(...urobilinogen_values);
      var urobilinogen_minIndex = urobilinogen_values.findIndex(
        (value) => value == urobilinogen_minValue
      );
      var urobilinogen_text = null;
      var urobilinogen_score = null;
      if (urobilinogen_minIndex == 0) {
        urobilinogen_text = "좋음";
        urobilinogen_score = 100;
        urobilinogen_result_text.push(urobilinogen_text);
        urobilinogen_result_score.push(urobilinogen_score);
      } else if (urobilinogen_minIndex == 1) {
        urobilinogen_text = "적절";
        urobilinogen_score = 75;
        urobilinogen_result_text.push(urobilinogen_text);
        urobilinogen_result_score.push(urobilinogen_score);
      } else if (urobilinogen_minIndex == 2) {
        urobilinogen_text = "주의";
        urobilinogen_score = 50;
        urobilinogen_result_text.push(urobilinogen_text);
        urobilinogen_result_score.push(urobilinogen_score);
      } else if (urobilinogen_minIndex == 3 || urobilinogen_minIndex == 4) {
        urobilinogen_text = "경고";
        urobilinogen_score = 25;
        urobilinogen_result_text.push(urobilinogen_text);
        urobilinogen_result_score.push(urobilinogen_score);
      } else {
        urobilinogen_text = "X";
        urobilinogen_score = 0;
        urobilinogen_result_text.push(urobilinogen_text);
        urobilinogen_result_score.push(urobilinogen_score);
      }
      worksheet.getCell(`K${rowIndex + 4 + index * 20}`).value =
        urobilinogen_result_text[0];
      worksheet.getCell(`L${rowIndex + 4 + index * 20}`).value =
        urobilinogen_result_score[0];

      // 케톤체 결과
      const ketone_result_text = [];
      const ketone_result_score = [];
      const ketone_values = [
        data.ketoneArrre0,
        data.ketoneArrre1,
        data.ketoneArrre2,
        data.ketoneArrre3,
        // data.ketoneArrre4,
      ];
      var ketone_minValue = Math.min(...ketone_values);
      var ketone_minIndex = ketone_values.findIndex(
        (value) => value == ketone_minValue
      );
      var ketone_text = null;
      var ketone_score = null;
      if (ketone_minIndex == 0) {
        ketone_text = "좋음";
        ketone_score = 100;
        ketone_result_text.push(ketone_text);
        ketone_result_score.push(ketone_score);
      } else if (ketone_minIndex == 1) {
        ketone_text = "적절";
        ketone_score = 75;
        ketone_result_text.push(ketone_text);
        ketone_result_score.push(ketone_score);
      } else if (ketone_minIndex == 2) {
        ketone_text = "주의";
        ketone_score = 50;
        ketone_result_text.push(ketone_text);
        ketone_result_score.push(ketone_score);
      } else if (ketone_minIndex == 3) {
        ketone_text = "경고";
        ketone_score = 25;
        ketone_result_text.push(ketone_text);
        ketone_result_score.push(ketone_score);
      } else {
        ketone_text = "X";
        ketone_score = 0;
        ketone_result_text.push(ketone_text);
        ketone_result_score.push(ketone_score);
      }
      worksheet.getCell(`K${rowIndex + 6 + index * 20}`).value =
        ketone_result_text[0];
      worksheet.getCell(`L${rowIndex + 6 + index * 20}`).value =
        ketone_result_score[0];

      // 단백질 결과
      const protein_result_text = [];
      const protein_result_score = [];
      const protein_values = [
        data.proteinArrre0,
        data.proteinArrre1,
        data.proteinArrre2,
        data.proteinArrre3,
        data.proteinArrre4,
        data.proteinArrre5,
      ];
      var protein_minValue = Math.min(...protein_values);
      var protein_minIndex = protein_values.findIndex(
        (value) => value == protein_minValue
      );
      var protein_text = null;
      var protein_score = null;
      if (protein_minIndex == 0) {
        protein_text = "좋음";
        protein_score = 100;
        protein_result_text.push(protein_text);
        protein_result_score.push(protein_score);
      } else if (protein_minIndex == 1) {
        protein_text = "적절";
        protein_score = 75;
        protein_result_text.push(protein_text);
        protein_result_score.push(protein_score);
      } else if (protein_minIndex == 2 || protein_minIndex == 3) {
        protein_text = "주의";
        protein_score = 50;
        protein_result_text.push(protein_text);
        protein_result_score.push(protein_score);
      } else if (protein_minIndex == 4 || protein_minIndex == 5) {
        protein_text = "경고";
        protein_score = 25;
        protein_result_text.push(protein_text);
        protein_result_score.push(protein_score);
      } else {
        protein_text = "X";
        protein_score = 0;
        protein_result_text.push(protein_text);
        protein_result_score.push(protein_score);
      }
      worksheet.getCell(`K${rowIndex + 8 + index * 20}`).value =
        protein_result_text[0];
      worksheet.getCell(`L${rowIndex + 8 + index * 20}`).value =
        protein_result_score[0];

      // 아질산염 결과
      const nitrituria_result_text = [];
      const nitrituria_result_score = [];
      const nitrituria_values = [data.nitrituriaArrre0, data.nitrituriaArrre1];
      var nitrituria_minValue = Math.min(...nitrituria_values);
      var nitrituria_minIndex = nitrituria_values.findIndex(
        (value) => value == nitrituria_minValue
      );
      var nitrituria_text = null;
      var nitrituria_score = null;
      if (nitrituria_minIndex == 0) {
        nitrituria_text = "좋음";
        nitrituria_score = 100;
        nitrituria_result_text.push(nitrituria_text);
        nitrituria_result_score.push(nitrituria_score);
      } else if (nitrituria_minIndex == 1) {
        nitrituria_text = "경고";
        nitrituria_score = 25;
        nitrituria_result_text.push(nitrituria_text);
        nitrituria_result_score.push(nitrituria_score);
      } else {
        nitrituria_text = "X";
        nitrituria_score = 0;
        nitrituria_result_text.push(nitrituria_text);
        nitrituria_result_score.push(nitrituria_score);
      }
      worksheet.getCell(`K${rowIndex + 10 + index * 20}`).value =
        nitrituria_result_text[0];
      worksheet.getCell(`L${rowIndex + 10 + index * 20}`).value =
        nitrituria_result_score[0];

      // 포도당 결과
      const glucose_result_text = [];
      const glucose_result_score = [];
      const glucose_values = [
        data.glucoseArrre0,
        data.glucoseArrre1,
        data.glucoseArrre2,
        data.glucoseArrre3,
        data.glucoseArrre4,
        data.glucoseArrre5,
      ];
      var glucose_minValue = Math.min(...glucose_values);
      var glucose_minIndex = glucose_values.findIndex(
        (value) => value == glucose_minValue
      );
      var glucose_text = null;
      var glucose_score = null;
      if (glucose_minIndex == 0) {
        glucose_text = "좋음";
        glucose_score = 100;
        glucose_result_text.push(glucose_text);
        glucose_result_score.push(glucose_score);
      } else if (glucose_minIndex == 1) {
        glucose_text = "적절";
        glucose_score = 75;
        glucose_result_text.push(glucose_text);
        glucose_result_score.push(glucose_score);
      } else if (glucose_minIndex == 2 || glucose_minIndex == 3) {
        glucose_text = "주의";
        glucose_score = 50;
        glucose_result_text.push(glucose_text);
        glucose_result_score.push(glucose_score);
      } else if (glucose_minIndex == 4 || glucose_minIndex == 5) {
        glucose_text = "경고";
        glucose_score = 25;
        glucose_result_text.push(glucose_text);
        glucose_result_score.push(glucose_score);
      } else {
        glucose_text = "X";
        glucose_score = 0;
        glucose_result_text.push(glucose_text);
        glucose_result_score.push(glucose_score);
      }
      worksheet.getCell(`K${rowIndex + 12 + index * 20}`).value =
        glucose_result_text[0];
      worksheet.getCell(`L${rowIndex + 12 + index * 20}`).value =
        glucose_result_score[0];

      // ph 결과
      const ph_result_text = [];
      const ph_result_score = [];
      const ph_values = [
        data.phArrre0,
        data.phArrre1,
        data.phArrre2,
        data.phArrre3,
        data.phArrre4,
      ];
      var ph_minValue = Math.min(...ph_values);
      var ph_minIndex = ph_values.findIndex((value) => value == ph_minValue);
      var ph_text = null;
      var ph_score = null;
      if (ph_minIndex == 0 || ph_minIndex == 1) {
        ph_text = "좋음";
        ph_score = 100;
        ph_result_text.push(ph_text);
        ph_result_score.push(ph_score);
      } else if (ph_minIndex == 2 || ph_minIndex == 3) {
        ph_text = "적절";
        ph_score = 75;
        ph_result_text.push(ph_text);
        ph_result_score.push(ph_score);
      } else if (ph_minIndex == 4) {
        ph_text = "주의";
        ph_score = 50;
        ph_result_text.push(ph_text);
        ph_result_score.push(ph_score);
      } else {
        ph_text = "X";
        ph_score = 0;
        ph_result_text.push(ph_text);
        ph_result_score.push(ph_score);
      }
      worksheet.getCell(`K${rowIndex + 14 + index * 20}`).value =
        ph_result_text[0];
      worksheet.getCell(`L${rowIndex + 14 + index * 20}`).value =
        ph_result_score[0];

      // 비중 결과
      const gravity_result_text = [];
      const gravity_result_score = [];
      const gravity_values = [
        data.gravityArrre0,
        data.gravityArrre1,
        data.gravityArrre2,
        data.gravityArrre3,
        data.gravityArrre4,
        data.gravityArrre5,
      ];
      var gravity_minValue = Math.min(...gravity_values);
      var gravity_minIndex = gravity_values.findIndex(
        (value) => value == gravity_minValue
      );
      var gravity_text = null;
      var gravity_score = null;
      if (gravity_minIndex == 0) {
        gravity_text = "좋음";
        gravity_score = 100;
        gravity_result_text.push(gravity_text);
        gravity_result_score.push(gravity_score);
      } else if (gravity_minIndex == 1) {
        gravity_text = "적절";
        gravity_score = 75;
        gravity_result_text.push(gravity_text);
        gravity_result_score.push(gravity_score);
      } else if (gravity_minIndex == 2 || gravity_minIndex == 3) {
        gravity_text = "주의";
        gravity_score = 50;
        gravity_result_text.push(gravity_text);
        gravity_result_score.push(gravity_score);
      } else if (gravity_minIndex == 4 || gravity_minIndex == 5) {
        gravity_text = "경고";
        gravity_score = 25;
        gravity_result_text.push(gravity_text);
        gravity_result_score.push(gravity_score);
      } else {
        gravity_text = "X";
        gravity_score = 0;
        gravity_result_text.push(gravity_text);
        gravity_result_score.push(gravity_score);
      }
      worksheet.getCell(`K${rowIndex + 16 + index * 20}`).value =
        gravity_result_text[0];
      worksheet.getCell(`L${rowIndex + 16 + index * 20}`).value =
        gravity_result_score[0];

      // 백혈구 결과
      const leukocyte_result_text = [];
      const leukocyte_result_score = [];
      const leukocyte_values = [
        data.leukocyteArrre0,
        data.leukocyteArrre1,
        data.leukocyteArrre2,
        data.leukocyteArrre3,
      ];
      var leukocyte_minValue = Math.min(...leukocyte_values);
      var leukocyte_minIndex = leukocyte_values.findIndex(
        (value) => value == leukocyte_minValue
      );
      var leukocyte_text = null;
      var leukocyte_score = null;
      if (leukocyte_minIndex == 0) {
        leukocyte_text = "좋음";
        leukocyte_score = 100;
        leukocyte_result_text.push(leukocyte_text);
        leukocyte_result_score.push(leukocyte_score);
      } else if (leukocyte_minIndex == 1) {
        leukocyte_text = "적절";
        leukocyte_score = 75;
        leukocyte_result_text.push(leukocyte_text);
        leukocyte_result_score.push(leukocyte_score);
      } else if (leukocyte_minIndex == 2) {
        leukocyte_text = "주의";
        leukocyte_score = 50;
        leukocyte_result_text.push(leukocyte_text);
        leukocyte_result_score.push(leukocyte_score);
      } else if (leukocyte_minIndex == 3) {
        leukocyte_text = "경고";
        leukocyte_score = 25;
        leukocyte_result_text.push(leukocyte_text);
        leukocyte_result_score.push(leukocyte_score);
      } else {
        leukocyte_text = "X";
        leukocyte_score = 0;
        leukocyte_result_text.push(leukocyte_text);
        leukocyte_result_score.push(leukocyte_score);
      }
      worksheet.getCell(`K${rowIndex + 18 + index * 20}`).value =
        leukocyte_result_text[0];
      worksheet.getCell(`L${rowIndex + 18 + index * 20}`).value =
        leukocyte_result_score[0];
    });

    // 칸 중앙 정렬
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });
    worksheet.columns.forEach((column) => {
      column.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });

    // 20행 마다 밑줄
    for (let i = 0; i < datalist.length; i++) {
      var bottom = 1;
      worksheet.eachRow({ includeEmpty: true }, (row, bottom) => {
        if (bottom % 20 == 1) {
          row.eachCell((cell) => {
            cell.border = {
              bottom: { style: "medium", color: { argb: "000000" } },
            };
          });
        }
      });
      bottom = bottom + 20;
    }

    await this.excel_img(data);

    return true;
  };

  excel_img = async (data) => {
    const worksheet_addImg = data.map(async (item, index) => {
      const columnIndex = 2 + index * 20;
      const test = await this.base64(item.img);
      const imageId1 = workbook.addImage({
        base64: test,
        extension: "jpg",
      });
      worksheet.addImage(imageId1, `A${columnIndex}:A${columnIndex + 19}`);
      return worksheet_addImg;
    });
  };

  excel_export = async (excel_data) => {
    const bufferPromise = workbook.xlsx.writeBuffer();
    bufferPromise.then((buffer) => {
      const excelFile = new Blob([buffer], { type: excelFileType });
      FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    });
  };

  rgbToHex(rgb) {
    const [r, g, b] = rgb.split(",").map((color) => parseInt(color));
    const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
    return hex;
  }

  componentDidMount() {
    $(".inputSearch").css("display", "none");
    $(".nice-dates input").css("display", "block");
    $(".Navigation #menuBtn2 img").attr("src", "/menu2_green.svg");
    $(".Navigation #menuBtn2").css({
      color: "#368552",
      "background-color": "#5ab3795d",
      "font-weight": "bold",
    });
    this.getDiagnosis();

    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    this.setState({
      today_DATE: year + "-" + month + "-" + day,
      this_year: year,
    });

    if (window.location.href.includes("num")) {
      const href = window.location.href;
      const numParam = href.split("num=")[1];
      const numValue =
        numParam && numParam.includes("#") ? numParam.split("#")[0] : numParam;
      this.setState(
        {
          idx: numValue,
        },
        () => {
          this.getDiagnosis_detail();
        }
      );
    }
  }

  selectSearch = (e) => {
    this.setState({
      defaulSelect: e.target.value,
    });
    if (e.target.value == "검사결과날짜") {
      $(".inputSearch").css("display", "none");
      $(".nice-dates input").css("display", "block");
    } else {
      $(".inputSearch").css("display", "flex");
      $(".nice-dates input").css("display", "none");
    }
  };
  handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      this.querySearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  textSearch = (e) => {
    this.setState({
      set_textSearch: e.target.value,
    });
  };

  querySearch = async () => {
    const defaulSelect = this.state.defaulSelect;
    // const set_textSearch = this.state.set_textSearch;
    const dateVal = $(".nice-dates input").val();
    if (
      // (set_textSearch == null ||
      //   set_textSearch == " " ||
      //   set_textSearch == "") &&
      dateVal == null ||
      dateVal == " " ||
      dateVal == ""
    ) {
      alert("날짜를 선택해주세요.");
    } else {
      if (defaulSelect == "결과") {
        // if (set_textSearch == "좋음") {
        // } else {
        //   alert("일치하는 검색어가 없습니다. 다시 입력해주세요!");
        // }
      } else if (defaulSelect == "검사결과날짜") {
        const res = await axios
          .get("/admin/get-createdDate/diagnosis" + dateVal)
          .then((res) => {
            this.setState({
              diagnosisList: res.data.diagnosis_res,
              currentPage: 1,
            });
          })
          .catch((error) => {
            alert("정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.");
          });
      } else {
        alert("잘못된 입력입니다!");
      }
    }
  };

  getDiagnosis = async () => {
    const res = await axios
      .get("/admin/get/diagnosis")
      .then((res) => {
        this.setState({
          diagnosisList: res.data.diagnosis_res,
        });
      })
      .catch((error) => {
        alert("정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.");
      });
  };

  getDiagnosis_detail = async () => {
    const idx = this.state.idx;
    const res = await axios
      .get("/admin/get2/diagnosis" + idx)
      .then((res) => {
        const createdDate =
          res.data.diagnosis_res[0].created_date.split("T")[0];
        const user_idx = res.data.diagnosis_res[0].user_idx;

        this.setState({
          diagnosisList_detail: res.data.diagnosis_res[0],
          diagnosisList_detail_date: createdDate,
          user_idx: user_idx,
        });

        this.getDiagnosis_detail_user(user_idx);
        this.guaiac(res.data.diagnosis_res[0]); // 잠혈
        this.bilirubin(res.data.diagnosis_res[0]); // 빌리루빈
        this.urobilinogen(res.data.diagnosis_res[0]); //우로빌리노겐
        this.ketone(res.data.diagnosis_res[0]); //케톤체
        this.protein(res.data.diagnosis_res[0]); //단백질
        this.nitrituria(res.data.diagnosis_res[0]); //아질산염
        this.glucose(res.data.diagnosis_res[0]); //포도당
        this.ph(res.data.diagnosis_res[0]); //ph
        this.gravity(res.data.diagnosis_res[0]); //비중
        this.leukocyte(res.data.diagnosis_res[0]); //백혈구
      })
      .catch((error) => {
        alert("정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.");
      });
  };

  getDiagnosis_detail_user = async (e) => {
    const user_idx = e;
    const res = await axios
      .get("/admin/get3/diagnosis" + user_idx)
      .then((res) => {
        const brith = res.data.diagnosis_res[0].birth.split("T")[0];
        const brith_year = res.data.diagnosis_res[0].birth.split("-")[0];

        const sexVal = res.data.diagnosis_res[0].sex;
        if (sexVal == 0) {
          this.setState({
            user_sex: "남",
          });
        } else if (sexVal == 1) {
          this.setState({
            user_sex: "여",
          });
        } else {
          this.setState({
            user_sex: "-",
          });
        }
        this.setState({
          user_name: res.data.diagnosis_res[0].name,
          user_brith: brith,
          user_age: this.state.this_year - brith_year,
          user_email: res.data.diagnosis_res[0].email,
          user_height: res.data.diagnosis_res[0].height,
          user_weight: res.data.diagnosis_res[0].weight,
          user_drinking: res.data.diagnosis_res[0].drinking,
          user_smoking: res.data.diagnosis_res[0].smoking,
          user_chronic_disease: res.data.diagnosis_res[0].chronic_disease,
        });
      })
      .catch((error) => {
        alert("정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.");
      });
  };

  // 잠혈
  guaiac = (e) => {
    const values = [
      e.guaiacArrre0,
      e.guaiacArrre1,
      e.guaiacArrre2,
      e.guaiacArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        guaiac_text: "좋음",
        guaiac_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        guaiac_text: "적절",
        guaiac_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        guaiac_text: "주의",
        guaiac_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        guaiac_text: "경고",
        guaiac_score: 25,
      });
    } else {
      this.setState({
        guaiac_text: "X",
        guaiac_score: 0,
      });
    }
  };

  //빌리루빈
  bilirubin = (e) => {
    const values = [
      e.bilirubinArrre0,
      e.bilirubinArrre1,
      e.bilirubinArrre2,
      e.bilirubinArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        bilirubin_text: "좋음",
        bilirubin_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        bilirubin_text: "적절",
        bilirubin_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        bilirubin_text: "주의",
        bilirubin_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        bilirubin_text: "경고",
        bilirubin_score: 25,
      });
    } else {
      this.setState({
        bilirubin_text: "X",
        bilirubin_score: 0,
      });
    }
  };

  //우로빌리노겐
  urobilinogen = (e) => {
    const values = [
      e.urobilinogenArrre0,
      e.urobilinogenArrre1,
      e.urobilinogenArrre2,
      e.urobilinogenArrre3,
      e.urobilinogenArrre4,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        urobilinogen_text: "좋음",
        urobilinogen_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        urobilinogen_text: "적절",
        urobilinogen_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        urobilinogen_text: "주의",
        urobilinogen_score: 50,
      });
    } else if (minIndex == 3 || minIndex == 4) {
      this.setState({
        urobilinogen_text: "경고",
        urobilinogen_score: 25,
      });
    } else {
      this.setState({
        urobilinogen_text: "X",
        urobilinogen_score: 0,
      });
    }
  };

  //케톤체
  ketone = (e) => {
    const values = [
      e.ketoneArrre0,
      e.ketoneArrre1,
      e.ketoneArrre2,
      e.ketoneArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        ketone_text: "좋음",
        ketone_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        ketone_text: "적절",
        ketone_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        ketone_text: "주의",
        ketone_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        ketone_text: "경고",
        ketone_score: 25,
      });
    } else {
      this.setState({
        ketone_text: "X",
        ketone_score: 0,
      });
    }
  };

  //단백질
  protein = (e) => {
    const values = [
      e.proteinArrre0,
      e.proteinArrre1,
      e.proteinArrre2,
      e.proteinArrre3,
      e.proteinArrre4,
      e.proteinArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        protein_text: "좋음",
        protein_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        protein_text: "적절",
        protein_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        protein_text: "주의",
        protein_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        protein_text: "경고",
        protein_score: 25,
      });
    } else {
      this.setState({
        protein_text: "X",
        protein_score: 0,
      });
    }
  };

  //아질산염
  nitrituria = (e) => {
    const values = [e.nitrituriaArrre0, e.nitrituriaArrre1];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        nitrituria_text: "좋음",
        nitrituria_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        nitrituria_text: "경고",
        nitrituria_score: 25,
      });
    } else {
      this.setState({
        nitrituria_text: "X",
        nitrituria_score: 0,
      });
    }
  };

  //포도당
  glucose = (e) => {
    const values = [
      e.glucoseArrre0,
      e.glucoseArrre1,
      e.glucoseArrre2,
      e.glucoseArrre3,
      e.glucoseArrre4,
      e.glucoseArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        glucose_text: "좋음",
        glucose_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        glucose_text: "적절",
        glucose_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        glucose_text: "주의",
        glucose_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        glucose_text: "경고",
        glucose_score: 25,
      });
    } else {
      this.setState({
        glucose_text: "X",
        glucose_score: 0,
      });
    }
  };

  //ph
  ph = (e) => {
    const values = [e.phArrre0, e.phArrre1, e.phArrre2, e.phArrre3, e.phArrre4];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0 || minIndex == 1) {
      this.setState({
        ph_text: "좋음",
        ph_score: 100,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        ph_text: "적절",
        ph_score: 75,
      });
    } else if (minIndex == 4) {
      this.setState({
        ph_text: "주의",
        ph_score: 50,
      });
    } else {
      this.setState({
        ph_text: "X",
        ph_score: 0,
      });
    }
  };

  //비중
  gravity = (e) => {
    const values = [
      e.gravityArrre0,
      e.gravityArrre1,
      e.gravityArrre2,
      e.gravityArrre3,
      e.gravityArrre4,
      e.gravityArrre5,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        gravity_text: "좋음",
        gravity_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        gravity_text: "적절",
        gravity_score: 75,
      });
    } else if (minIndex == 2 || minIndex == 3) {
      this.setState({
        gravity_text: "주의",
        gravity_score: 50,
      });
    } else if (minIndex == 4 || minIndex == 5) {
      this.setState({
        gravity_text: "경고",
        gravity_score: 25,
      });
    } else {
      this.setState({
        gravity_text: "X",
        gravity_score: 0,
      });
    }
  };

  //백혈구
  leukocyte = (e) => {
    const values = [
      e.leukocyteArrre0,
      e.leukocyteArrre1,
      e.leukocyteArrre2,
      e.leukocyteArrre3,
    ];
    var minValue = Math.min(...values);
    var minIndex = values.findIndex((value) => value == minValue);

    if (minIndex == 0) {
      this.setState({
        leukocyte_text: "좋음",
        leukocyte_score: 100,
      });
    } else if (minIndex == 1) {
      this.setState({
        leukocyte_text: "적절",
        leukocyte_score: 75,
      });
    } else if (minIndex == 2) {
      this.setState({
        leukocyte_text: "주의",
        leukocyte_score: 50,
      });
    } else if (minIndex == 3) {
      this.setState({
        leukocyte_text: "경고",
        leukocyte_score: 25,
      });
    } else {
      this.setState({
        leukocyte_text: "X",
        leukocyte_score: 0,
      });
    }
  };

  href_menu() {
    window.location.href = "/Admin_Page/Diagnosis";
  }

  // Detail 삭제
  del = async () => {
    const checkedIds = [this.state.idx];
    const res = await axios
      .delete("/admin/delete/diagnosis" + checkedIds)
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) =>
        alert("삭제하지 못했습니다. 관리자에게 문의해 주세요.")
      );
    window.location.href = "/Admin_Page/Diagnosis";
  };

  render() {
    const { data } = this.state;

    const average_score =
      (this.state.guaiac_score +
        this.state.bilirubin_score +
        this.state.urobilinogen_score +
        this.state.ketone_score +
        this.state.protein_score +
        this.state.nitrituria_score +
        this.state.glucose_score +
        this.state.ph_score +
        this.state.gravity_score +
        this.state.leukocyte_score) /
      10;

    let count_1 = 0;
    let count_2 = 0;
    let count_3 = 0;
    let count_4 = 0;
    const count_list = [
      this.state.guaiac_text,
      this.state.bilirubin_text,
      this.state.urobilinogen_text,
      this.state.ketone_text,
      this.state.protein_text,
      this.state.nitrituria_text,
      this.state.glucose_text,
      this.state.ph_text,
      this.state.gravity_text,
      this.state.leukocyte_text,
    ];
    for (let i = 0; i < count_list.length; i++) {
      if (count_list[i] == "좋음") {
        count_1 = count_1 + 1;
      }
      if (count_list[i] == "적절") {
        count_2 = count_2 + 1;
      }
      if (count_list[i] == "주의") {
        count_3 = count_3 + 1;
      }
      if (count_list[i] == "경고") {
        count_4 = count_4 + 1;
      }
    }

    return (
      <div className="Diagnosis">
        <Routes>
          {/* 리스트 */}
          <Route
            path="/"
            element={
              <>
                <div className="title">
                  <div className="title_text">검사결과</div>
                  <span>(한 페이지당 보이는 개수 : 10)</span>
                  <div
                    className="down"
                    onClick={() => this.excelDownload_start()}
                  >
                    <img className="down" src="/down.svg" />
                    <span>다운로드</span>
                  </div>
                  <span>* 다운로드는 한페이지 당 최대 10개씩의 데이터만 다운받을 수 있습니다.</span>
                  <div className="serchBar">
                    <select
                      name="selectSearch"
                      id="selectSearch"
                      onChange={this.selectSearch}
                    >
                      <option value="검사결과날짜" defaultValue>
                        검사결과날짜
                      </option>
                      {/* <option value="검사결과날짜">검사 결과 날짜</option> */}
                    </select>
                    <Calendar />
                    {/* <input
                      type="text"
                      placeholder="좋음, 적절, 주의, 경고"
                      className="inputSearch"
                      onChange={this.textSearch}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                    /> */}
                    <img
                      className="querySearch"
                      src="/search.svg"
                      onClick={this.querySearch}
                    />
                  </div>
                </div>

                <div className="Diagnosis_board">
                  <Diagnosis_List
                    diagnosisList={this.state.diagnosisList}
                    currentPage={this.state.currentPage}
                  />
                </div>
              </>
            }
          />

          {/* 상세페이지 */}
          <Route
            path="/Diagnosis_detail/*"
            element={
              <>
                <div className="title">
                  <img
                    className="double_left"
                    src="/double_left.svg"
                    alt="검사결과 메뉴로 나가기"
                    onClick={this.href_menu}
                  />
                  <div className="title_text">
                    <span>
                      이름 : {this.state.user_name}({this.state.user_sex})
                    </span>
                    <span>나이 : {this.state.user_age}세</span>
                  </div>
                  <div className="title_date">
                    검사 결과 날짜 : {this.state.diagnosisList_detail_date}
                  </div>
                  <img
                    className="del"
                    src="/trash.svg"
                    alt="삭제"
                    title="삭제"
                    onClick={this.del}
                  />
                </div>

                <div className="Diagnosis_detail">
                  {/* 유저정보 */}
                  <div className="user_wrap_title">유저 정보</div>
                  <div className="user_wrap">
                    <div className="diagnosisImg">
                      <img
                        style={{ height: "100%", borderRadius: "15px" }}
                        src={this.state.diagnosisList_detail.img}
                      />
                    </div>
                    <div className="userInformation">
                      <div>
                        <span>이름 : {this.state.user_name}</span>
                        <span>생일 : {this.state.user_brith}</span>
                      </div>
                      <div>
                        <span>성별 : {this.state.user_sex}</span>
                        <span>이메일 : {this.state.user_email}</span>
                      </div>
                      <div>
                        <span>키 : {this.state.user_height}</span>
                        <span>체중 : {this.state.user_weight}</span>
                      </div>
                      <div>
                        <span>음주량 : {this.state.user_drinking}</span>
                        <span>흡연량 : {this.state.user_smoking}</span>
                      </div>
                      <div>
                        <span>
                          만성질환 : {this.state.user_chronic_disease}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* 검사결과 */}
                  <div className="diagnosis_wrap_title">검사 결과</div>
                  <div className="diagnosis_wrap">
                    <div className="diagnosis_wrap_top">
                      <div>
                        총 점수 평균 : {average_score}점
                        <span
                          style={{
                            color:
                              average_score <= 25
                                ? "#E80025"
                                : average_score <= 50
                                ? "#E86700"
                                : average_score <= 75
                                ? "#0025E8"
                                : average_score <= 100
                                ? "#333333"
                                : "#333333",
                          }}
                        >
                          (
                          {average_score <= 25
                            ? "경고"
                            : average_score <= 50
                            ? "주의"
                            : average_score <= 75
                            ? "적절"
                            : average_score <= 100
                            ? "좋음"
                            : ""}
                          )
                        </span>
                      </div>
                      <div style={{ color: "#333333" }}>좋음 {count_1}건</div>
                      <div style={{ color: "#0025E8" }}>적절 {count_2}건</div>
                      <div style={{ color: "#E86700" }}>주의 {count_3}건</div>
                      <div style={{ color: "#E80025" }}>경고 {count_4}건</div>
                    </div>

                    {/* diagnosis_box */}
                    <div className="order_box">
                      <div>항목</div>
                      <div>
                        <div>검사자 비색표</div>
                      </div>
                      <div className="order">
                        <div>음성(-)</div>
                        <div>±</div>
                        <div>+</div>
                        <div>++</div>
                        <div>+++</div>
                        <div>++++</div>
                      </div>
                      <div
                        className="result"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div className="result">결과</div>
                      <div className="result">점수</div>
                    </div>

                    <div className="diagnosis_box" id="box_1">
                      <div className="name">잠혈</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.guaiaccolor +
                              ")",
                          }}
                        >
                          rgb({this.state.diagnosisList_detail.guaiaccolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.guaiacArr0 +
                                ")",
                            }}
                          >
                            0
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.guaiacArr1 +
                                ")",
                            }}
                          >
                            10
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.guaiacArr2 +
                                ")",
                            }}
                          >
                            50
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.guaiacArr3 +
                                ")",
                            }}
                          >
                            250
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.guaiacArrre0
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.guaiacArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.guaiacArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.guaiacArrre3
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        id="re_text"
                        style={{
                          color:
                            this.state.guaiac_text === "좋음" ||
                            this.state.guaiac_text === ""
                              ? "#333333"
                              : this.state.guaiac_text === "적절"
                              ? "#0025E8"
                              : this.state.guaiac_text === "주의"
                              ? "#E86700"
                              : this.state.guaiac_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.guaiac_text}
                      </div>
                      <div className="result2">{this.state.guaiac_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_2">
                      <div className="name">빌리루빈</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.bilirubincolor +
                              ")",
                          }}
                        >
                          rgb({this.state.diagnosisList_detail.bilirubincolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.bilirubinArr0 +
                                ")",
                            }}
                          >
                            0.0
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.bilirubinArr1 +
                                ")",
                            }}
                          >
                            0.5
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.bilirubinArr2 +
                                ")",
                            }}
                          >
                            1.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.bilirubinArr3 +
                                ")",
                            }}
                          >
                            3.0
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.bilirubinArrre0
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.bilirubinArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.bilirubinArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.bilirubinArrre3
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.bilirubin_text === "좋음" ||
                            this.state.bilirubin_text === ""
                              ? "#333333"
                              : this.state.bilirubin_text === "적절"
                              ? "#0025E8"
                              : this.state.bilirubin_text === "주의"
                              ? "#E86700"
                              : this.state.bilirubin_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.bilirubin_text}
                      </div>
                      <div className="result2">
                        {this.state.bilirubin_score}
                      </div>
                    </div>

                    <div className="diagnosis_box" id="box_3">
                      <div className="name">우로빌리노겐</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail
                                .urobilinogencolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.urobilinogencolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail
                                  .urobilinogenArr0 +
                                ")",
                            }}
                          >
                            0.1
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail
                                  .urobilinogenArr1 +
                                ")",
                            }}
                          >
                            1.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail
                                  .urobilinogenArr2 +
                                ")",
                            }}
                          >
                            4.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail
                                  .urobilinogenArr3 +
                                ")",
                            }}
                          >
                            8.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail
                                  .urobilinogenArr4 +
                                ")",
                            }}
                          >
                            12.0
                          </div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.urobilinogenArrre0
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.urobilinogenArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.urobilinogenArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.urobilinogenArrre3
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.urobilinogenArrre4
                            ).toFixed(5)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.urobilinogen_text === "좋음" ||
                            this.state.urobilinogen_text === ""
                              ? "#333333"
                              : this.state.urobilinogen_text === "적절"
                              ? "#0025E8"
                              : this.state.urobilinogen_text === "주의"
                              ? "#E86700"
                              : this.state.urobilinogen_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.urobilinogen_text}
                      </div>
                      <div className="result2">
                        {this.state.urobilinogen_score}
                      </div>
                    </div>

                    <div className="diagnosis_box" id="box_4">
                      <div className="name">케톤체</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.ketonecolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.ketonecolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.ketoneArr0 +
                                ")",
                            }}
                          >
                            5
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.ketoneArr1 +
                                ")",
                            }}
                          >
                            10
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.ketoneArr2 +
                                ")",
                            }}
                          >
                            50
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.ketoneArr3 +
                                ")",
                            }}
                          >
                            100
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.ketoneArrre0
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.ketoneArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.ketoneArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.ketoneArrre3
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.ketone_text === "좋음" ||
                            this.state.ketone_text === ""
                              ? "#333333"
                              : this.state.ketone_text === "적절"
                              ? "#0025E8"
                              : this.state.ketone_text === "주의"
                              ? "#E86700"
                              : this.state.ketone_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.ketone_text}
                      </div>
                      <div className="result2">{this.state.ketone_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_5">
                      <div className="name">단백질</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.proteincolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.proteincolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr0 +
                                ")",
                            }}
                          >
                            0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr1 +
                                ")",
                            }}
                          >
                            10
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr2 +
                                ")",
                            }}
                          >
                            30
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr3 +
                                ")",
                            }}
                          >
                            100
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr4 +
                                ")",
                            }}
                          >
                            300
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.proteinArr5 +
                                ")",
                            }}
                          >
                            1000
                          </div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre0
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre3
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre4
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.proteinArrre5
                            ).toFixed(5)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.protein_text === "좋음" ||
                            this.state.protein_text === ""
                              ? "#333333"
                              : this.state.protein_text === "적절"
                              ? "#0025E8"
                              : this.state.protein_text === "주의"
                              ? "#E86700"
                              : this.state.protein_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.protein_text}
                      </div>
                      <div className="result2">{this.state.protein_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_6">
                      <div className="name">아질산염</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.nitrituriacolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.nitrituriacolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.nitrituriaArr0 +
                                ")",
                            }}
                          >
                            Neg
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.nitrituriaArr1 +
                                ")",
                            }}
                          >
                            pos
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.nitrituriaArrre0
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.nitrituriaArrre1
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.nitrituria_text === "좋음" ||
                            this.state.nitrituria_text === ""
                              ? "#333333"
                              : this.state.nitrituria_text === "적절"
                              ? "#0025E8"
                              : this.state.nitrituria_text === "주의"
                              ? "#E86700"
                              : this.state.nitrituria_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.nitrituria_text}
                      </div>
                      <div className="result2">
                        {this.state.nitrituria_score}
                      </div>
                    </div>

                    <div className="diagnosis_box" id="box_7">
                      <div className="name">포도당</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.glucosecolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.glucosecolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr0 +
                                ")",
                            }}
                          >
                            0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr1 +
                                ")",
                            }}
                          >
                            100
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr2 +
                                ")",
                            }}
                          >
                            250
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr3 +
                                ")",
                            }}
                          >
                            500
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr4 +
                                ")",
                            }}
                          >
                            1000
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.glucoseArr5 +
                                ")",
                            }}
                          >
                            2000
                          </div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre0
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre3
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre4
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.glucoseArrre5
                            ).toFixed(5)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.glucose_text === "좋음" ||
                            this.state.glucose_text === ""
                              ? "#333333"
                              : this.state.glucose_text === "적절"
                              ? "#0025E8"
                              : this.state.glucose_text === "주의"
                              ? "#E86700"
                              : this.state.glucose_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.glucose_text}
                      </div>
                      <div className="result2">{this.state.glucose_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_8">
                      <div className="name">ph</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.phcolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.phcolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.phArr0 +
                                ")",
                            }}
                          >
                            5.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.phArr1 +
                                ")",
                            }}
                          >
                            6.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.phArr2 +
                                ")",
                            }}
                          >
                            7.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.phArr3 +
                                ")",
                            }}
                          >
                            8.0
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.phArr4 +
                                ")",
                            }}
                          >
                            9.0
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.phArrre0
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.phArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.phArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.phArrre3
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.phArrre4
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.ph_text === "좋음" ||
                            this.state.ph_text === ""
                              ? "#333333"
                              : this.state.ph_text === "적절"
                              ? "#0025E8"
                              : this.state.ph_text === "주의"
                              ? "#E86700"
                              : this.state.ph_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.ph_text}
                      </div>
                      <div className="result2">{this.state.ph_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_9">
                      <div className="name">비중</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.gravitycolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.gravitycolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr0 +
                                ")",
                            }}
                          >
                            1.005
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr1 +
                                ")",
                            }}
                          >
                            1.010
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr2 +
                                ")",
                            }}
                          >
                            1.015
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr3 +
                                ")",
                            }}
                          >
                            1.020
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr4 +
                                ")",
                            }}
                          >
                            1.025
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.gravityArr4 +
                                ")",
                            }}
                          >
                            1.030
                          </div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre0
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre3
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre4
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.gravityArrre5
                            ).toFixed(5)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.gravity_text === "좋음" ||
                            this.state.gravity_text === ""
                              ? "#333333"
                              : this.state.gravity_text === "적절"
                              ? "#0025E8"
                              : this.state.gravity_text === "주의"
                              ? "#E86700"
                              : this.state.gravity_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.gravity_text}
                      </div>
                      <div className="result2">{this.state.gravity_score}</div>
                    </div>

                    <div className="diagnosis_box" id="box_10">
                      <div className="name">백혈구</div>
                      <div className="result">
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              "rgb(" +
                              this.state.diagnosisList_detail.leukocytecolor +
                              ")",
                          }}
                        >
                          rgb(
                          {this.state.diagnosisList_detail.leukocytecolor})
                        </div>
                        <div className="color_name">유사도 수치 →</div>
                      </div>
                      <div className="standard">
                        <div className="standard_color">
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.leukocyteArr0 +
                                ")",
                            }}
                          >
                            0.1
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.leukocyteArr1 +
                                ")",
                            }}
                          >
                            0.1
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.leukocyteArr2 +
                                ")",
                            }}
                          >
                            0.1
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                "rgb(" +
                                this.state.diagnosisList_detail.leukocyteArr3 +
                                ")",
                            }}
                          >
                            0.1
                          </div>{" "}
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                        <div className="similarity">
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.leukocyteArrre0
                            ).toFixed(5)}
                          </div>{" "}
                          <div style={{ visibility: "hidden" }}></div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.leukocyteArrre1
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.leukocyteArrre2
                            ).toFixed(5)}
                          </div>
                          <div>
                            {parseFloat(
                              this.state.diagnosisList_detail.leukocyteArrre3
                            ).toFixed(5)}
                          </div>
                          <div style={{ visibility: "hidden" }}></div>
                        </div>
                      </div>

                      <div
                        className="result2"
                        style={{ visibility: "hidden" }}
                      ></div>
                      <div
                        className="result2"
                        style={{
                          color:
                            this.state.leukocyte_text === "좋음" ||
                            this.state.leukocyte_text === ""
                              ? "#333333"
                              : this.state.leukocyte_text === "적절"
                              ? "#0025E8"
                              : this.state.leukocyte_text === "주의"
                              ? "#E86700"
                              : this.state.leukocyte_text === "경고"
                              ? "#E80025"
                              : "#333333",
                        }}
                      >
                        {this.state.leukocyte_text}
                      </div>
                      <div className="result2">
                        {this.state.leukocyte_score}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </div>
    );
  }
}

export default Diagnosis;

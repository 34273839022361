import React, { Component } from "react";
import "./css/Notice_2.css";

class Notice_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  move_Notice_detail = (e) => {
    window.location.href =
      "/Admin_Page/Notice/Notice_detail/num=" + this.props.idx;
  };

  render() {
    const createdDate = this.props.created_date.split("T")[0];
    return (
      <div className="Notice_2">
        <span>
          <input type="checkbox" id={this.props.idx} name="notice_check" />
        </span>
        <span className="idxDiv" style={{ display: "none" }}>
          {this.props.idx}
        </span>
        <span onClick={(e) => this.move_Notice_detail(e)}>
          {this.props.title}
        </span>
        <span onClick={(e) => this.move_Notice_detail(e)}>
          {this.props.writer}
        </span>
        <span onClick={(e) => this.move_Notice_detail(e)}>{createdDate}</span>
        {/* <div>{this.props.contents}</div> */}
      </div>
    );
  }
}

export default Notice_2;

import React, { Component } from "react";
import "./css/Notice_1.css";
import Notice_2 from "./Notice_2.js";
import axios from "axios";

class Notice_1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
    };
    this.perPage = 10;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.state.currentPage) {
      this.setState({ currentPage: nextProps.currentPage });
    }
  }
  
  getCurrentPageData() {
    const { noticeList } = this.props;
    const { currentPage } = this.state;
    const startIndex = (currentPage - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    return noticeList.slice(startIndex, endIndex);
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleNextPage = () => {
    const { noticeList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(noticeList.length / this.perPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
  };

  renderPagination() {
    const { noticeList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(noticeList.length / this.perPage);

    const pageNumbers = [];
    const maxPageNumber = Math.min(totalPages, 10); // 최대 10개의 페이지 번호만 표시

    // 현재 페이지가 10 이하인 경우
    if (currentPage <= 10) {
      for (let i = 1; i <= maxPageNumber; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    } else {
      // 현재 페이지가 10 이상인 경우
      const startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
      const endPage = Math.min(startPage + 9, totalPages);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    }

    return <div className="pagination_num">{pageNumbers}</div>;
  }

  add_page() {
    window.location.href = "/Admin_Page/Notice/Notice_add";
  }

  del_check = async () => {
    const checkedIds = [];
    const checkboxes = document.getElementsByName("notice_check");

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedIds.push(checkbox.id);
      }
    });

    if (checkedIds.length === 0) {
      window.alert("삭제할 공지사항을 선택해주세요.");
      return;
    }
    const res = await axios
      .delete("/admin/delete/notice" + checkedIds)
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        alert("삭제하지 못했습니다. 관리자에게 문의해 주세요.");
      })
  };

  render() {
    const currentPageData = this.getCurrentPageData();
    const noticeList = currentPageData.map((data, index) => (
      <Notice_2
        key={index}
        idx={data.idx}
        title={data.title}
        writer={data.writer}
        created_date={data.created_date}
      />
    ));

    return (
      <div className="Notice_1">
        <div className="boxTitle">
          <span>
            <img src="/check_white.svg" />
          </span>
          <span className="idxDiv" style={{ display: "none" }}>
            번호
          </span>
          <span>제목</span>
          <span>작성자</span>
          <span>작성일</span>
        </div>
        <div className="noticeList_box">{noticeList}</div>
        {currentPageData.length > 0 && (
          <div className="pagination">
            <div className="plus" onClick={this.add_page}>
              <img className="file_plus" src="/file_plus.svg" />
              <span>추가하기</span>
            </div>
            <div className="minus" onClick={this.del_check}>
              <img className="file_minus" src="/file_minus.svg" />
              <span>삭제하기</span>
            </div>
            <button id="prev" onClick={this.handlePreviousPage}>
              <img src="/left.svg" alt="Previous" />
            </button>
            {this.renderPagination()}
            <button id="next" onClick={this.handleNextPage}>
              <img src="/right.svg" alt="Next" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Notice_1;

import React, { Component } from "react";
import "./css/Tos.css";
import $ from "jquery";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";

class Tos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsList: [],
      tosText: [],
      this_term: null,
      tos: null,
      personal_information: null,
      sensitive_information: null,
      edit_contents: null,
    };
  }

  componentDidMount() {
    $(".Navigation #menuBtn4 img").attr("src", "/menu4_green.svg");
    $(".Navigation #menuBtn4").css({
      color: "#368552",
      "background-color": "#5ab3795d",
      "font-weight": "bold",
    });
    $(".Tos > .title .edit_minus").css("display", "none");

    this.getTos_columns();
    this.getTos_texts();
  }

  getTos_columns = async () => {
    const res = await axios.get("/admin/get/tos_columns")
    .then((res) => {
      const termsList = res.data.tos_res.map((term) => {
        return term.COLUMN_NAME;
      });
      this.setState({
        termsList,
      });
    })
    .catch((error) => {
      alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
    })
  };

  getTos_texts = async () => {
    const res = await axios.get("/admin/get/tos")
    .then((res) => {
      var tosTexts = [];
      tosTexts = res.data.tos_res;
      this.setState({
        tos: tosTexts[0].tos,
        personal_information: tosTexts[0].personal_information,
        sensitive_information: tosTexts[0].sensitive_information,
      });
    })
    .catch((error) => {
      alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
    })
  };

  select_tos = async (e) => {
    const btn_id = e.target.id;
    this.setState({
      this_term: e.target.id,
    });
    $(".Tos > .Tos_board > .Tos_edit").css("display", "none");
    $(".Tos > .title .edit_minus .cancel_gay").css("display", "none");
    $(".Tos > .title .edit_minus .check_gay").css("display", "none");
    $(".Tos > .title .edit_minus").css("display", "flex");
    $(".Tos > .title .edit_minus .edit_gray").css("display", "flex");
    $(".Tos > .title .edit_minus .trash").css("display", "flex");
    $(".Tos > .title > .menu_wrap > .menuBtn").css({
      "background-color": "#d8d8d85d",
      color: "#3C3C3C",
    });
    $(".Tos > .title > .menu_wrap > .menuBtn img").attr(
      "src",
      "/file_text.svg"
    );
    $(".Tos > .Tos_board > .Tos_board_tos").css("display", "none");
    $(".Tos > .Tos_board > .Tos_board_personal_information").css(
      "display",
      "none"
    );
    $(".Tos > .Tos_board > .Tos_board_sensitive_information").css(
      "display",
      "none"
    );

    if (btn_id == "tos") {
      $(".Tos > .title > .menu_wrap > #tos").css({
        "background-color": "rgba(90, 179, 121, 0.365)",
        color: "rgb(54, 133, 82)",
      });
      $(".Tos > .title > .menu_wrap > #tos > img").attr(
        "src",
        "/file_text_green.svg"
      );
      $(".Tos > .Tos_board > .Tos_board_tos").css("display", "flex");
    } else if (btn_id == "personal_information") {
      $(".Tos > .title > .menu_wrap > #personal_information").css({
        "background-color": "rgba(90, 179, 121, 0.365)",
        color: "rgb(54, 133, 82)",
      });
      $(".Tos > .title > .menu_wrap > #personal_information > img").attr(
        "src",
        "/file_text_green.svg"
      );
      $(".Tos > .Tos_board > .Tos_board_personal_information").css(
        "display",
        "flex"
      );
    } else if (btn_id == "sensitive_information") {
      $(".Tos > .title > .menu_wrap > #sensitive_information").css({
        "background-color": "rgba(90, 179, 121, 0.365)",
        color: "rgb(54, 133, 82)",
      });
      $(".Tos > .title > .menu_wrap > #sensitive_information > img").attr(
        "src",
        "/file_text_green.svg"
      );
      $(".Tos > .Tos_board > .Tos_board_sensitive_information").css(
        "display",
        "flex"
      );
    }
  };

  edit = () => {
    $(".Tos > .Tos_board > .Tos_board_tos").css("display", "none");
    $(".Tos > .Tos_board > .Tos_board_personal_information").css(
      "display",
      "none"
    );
    $(".Tos > .Tos_board > .Tos_board_sensitive_information").css(
      "display",
      "none"
    );
    $(".Tos > .Tos_board > .Tos_edit").css("display", "flex");
    $(".Tos > .title .edit_minus").css("display", "flex");
    $(".Tos > .title .edit_minus .edit_gray").css("display", "none");
    $(".Tos > .title .edit_minus .trash").css("display", "none");
    $(".Tos > .title .edit_minus .cancel_gay").css("display", "flex");
    $(".Tos > .title .edit_minus .check_gay").css("display", "flex");
  };

  del = async () => {
    //<p>작성한 내용이 없습니다.</p> 변경
    const column = this.state.this_term;
    const res = axios
      .patch("/admin/patch/tos_del", { column: column })
      .then((res) => {})
      .catch((error) => {
        alert("삭제하지 못했습니다. 관리자에게 문의해 주세요.");
      });
    window.location.reload();
  };

  cancel = async () => {
    var val = this.state.this_term;
    if (val == "tos") {
      document.getElementById("tos").click();
    } else if (val == "personal_information") {
      document.getElementById("personal_information").click();
    } else if (val == "sensitive_information") {
      document.getElementById("sensitive_information").click();
    }
  };

  complete = async () => {
    const column = this.state.this_term;
    const contents = this.state.edit_contents;
    const res = await axios.patch("/admin/patch/tos", {
      column: column,
      contents: contents,
    });
    window.location.reload();
  };

  render() {
    const { termsList } = this.state;

    return (
      <div className="Tos">
        <div className="title">
          이용약관
          <div className="menu_wrap">
            {termsList.map((term, index) => (
              <button
                className="menuBtn"
                id={term}
                key={index}
                onClick={this.select_tos}
              >
                <img src="/file_text.svg" id={term} onClick={this.select_tos} />
                {term === "tos"
                  ? "서비스 이용약관"
                  : term === "personal_information"
                  ? "개인정보 수집약관"
                  : term === "sensitive_information"
                  ? "민감정보 수집약관"
                  : term}
              </button>
            ))}
          </div>
          {/* edit_minus */}
          <div className="edit_minus">
            {this.state.this_term && (
              <span>
                {this.state.this_term === "tos"
                  ? "서비스 이용약관"
                  : this.state.this_term === "personal_information"
                  ? "개인정보 수집약관"
                  : this.state.this_term === "sensitive_information"
                  ? "민감정보 수집약관"
                  : this.state.this_term}
              </span>
            )}
            <img
              className="edit_gray"
              src="/edit_gray.svg"
              title="편집"
              onClick={this.edit}
            />
            <img
              className="trash"
              src="/trash.svg"
              title="삭제"
              onClick={this.del}
            />
            <img
              className="cancel_gay"
              src="/cancel_gay.svg"
              title="취소"
              onClick={this.cancel}
            />
            <img
              className="check_gay"
              src="/check_gay.svg"
              title="편집완료"
              onClick={this.complete}
            />
          </div>
        </div>

        <div className="Tos_board">
          <div className="Tos_board_tos" id="Tos_board_all">
            {ReactHtmlParser(this.state.tos)}
          </div>
          <div className="Tos_board_personal_information" id="Tos_board_all">
            {ReactHtmlParser(this.state.personal_information)}
          </div>
          <div className="Tos_board_sensitive_information" id="Tos_board_all">
            {ReactHtmlParser(this.state.sensitive_information)}
          </div>

          <div className="Tos_edit">
            <CKEditor
              className="custom_editor"
              editor={ClassicEditor}
              config={{
                placeholder: "ReactHtmlParser(this.state.tos)",
                toolbar: {
                  items: [
                    "bold",
                    // 'numberedList',
                    // 'bulletedList',
                    "|",
                    "undo",
                    "redo",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "ko",
              }}
              data={
                this.state.this_term === "tos"
                  ? this.state.tos
                  : this.state.this_term === "personal_information"
                  ? this.state.personal_information
                  : this.state.this_term === "sensitive_information"
                  ? this.state.sensitive_information
                  : ""
              }
              onReady={(editor) => {
                this.editor = editor;
              }}
              onError={(error, { willEditorRestart }) => {
                if (willEditorRestart) {
                  this.editor.ui.view.toolbar.element.remove();
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({
                  edit_contents: data,
                });
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Tos;

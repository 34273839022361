import React, { Component } from "react";
import "./css/Diagnosis_1.css";
import Diagnosis_2 from "./Diagnosis_2.js";
import axios from "axios";

class Diagnosis_1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
    };
    this.perPage = 10;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.state.currentPage) {
      this.setState({ currentPage: nextProps.currentPage });
    }
  }

  getCurrentPageData() {
    const { diagnosisList } = this.props;
    const { currentPage } = this.state;
    const startIndex = (currentPage - 1) * this.perPage;
    const endIndex = startIndex + this.perPage;
    return diagnosisList.slice(startIndex, endIndex);
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleNextPage = () => {
    const { diagnosisList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(diagnosisList.length / this.perPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
  };

  renderPagination() {
    const { diagnosisList } = this.props;
    const { currentPage } = this.state;
    const totalPages = Math.ceil(diagnosisList.length / this.perPage);

    const pageNumbers = [];
    const maxPageNumber = Math.min(totalPages, 10); // 최대 10개의 페이지 번호만 표시

    // 현재 페이지가 10 이하인 경우
    if (currentPage <= 10) {
      for (let i = 1; i <= maxPageNumber; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    } else {
      // 현재 페이지가 10 이상인 경우
      const startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
      const endPage = Math.min(startPage + 9, totalPages);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={currentPage === i ? "active" : ""}
            onClick={() => this.handlePageClick(i)}
          >
            {currentPage === i ? <b>{i}</b> : i}
          </button>
        );
      }
    }

    return <div className="pagination_num">{pageNumbers}</div>;
  }

  del_check = async () => {
    const checkedIds = [];
    const checkboxes = document.getElementsByName("diagnosis_check");

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedIds.push(checkbox.id);
      }
    });

    if (checkedIds.length === 0) {
      window.alert("삭제할 검사결과를 선택해주세요.");
      return;
    }
    const res = await axios
      .delete("/admin/delete/diagnosis" + checkedIds)
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        alert("삭제하지 못했습니다. 관리자에게 문의해 주세요.");
      });
  };

  render() {
    const { data } = this.state;
    const currentPageData = this.getCurrentPageData();
    const diagnosisList = currentPageData.map((data, index) => (
      <Diagnosis_2
        key={index}
        idx={data.idx}
        user_idx={data.user_idx}
        img={data.img}
        guaiaccolor={data.guaiaccolor}
        guaiacArr0={data.guaiacArr0}
        guaiacArr1={data.guaiacArr1}
        guaiacArr2={data.guaiacArr2}
        guaiacArr3={data.guaiacArr3}
        guaiacArrre0={data.guaiacArrre0}
        guaiacArrre1={data.guaiacArrre1}
        guaiacArrre2={data.guaiacArrre2}
        guaiacArrre3={data.guaiacArrre3}
        bilirubincolor={data.bilirubincolor}
        bilirubinArr0b={data.bilirubinArr0b}
        bilirubinArr1b={data.bilirubinArr1b}
        bilirubinArr2b={data.bilirubinArr2b}
        bilirubinArr3b={data.bilirubinArr3b}
        bilirubinArrre0={data.bilirubinArrre0}
        bilirubinArrre1={data.bilirubinArrre1}
        bilirubinArrre2={data.bilirubinArrre2}
        bilirubinArrre3={data.bilirubinArrre3}
        urobilinogencolorgb={data.urobilinogencolorgb}
        urobilinogenArr0rgb={data.urobilinogenArr0rgb}
        urobilinogenArr1rgb={data.urobilinogenArr1rgb}
        urobilinogenArr2rgb={data.urobilinogenArr2rgb}
        urobilinogenArr3rgb={data.urobilinogenArr3rgb}
        urobilinogenArr4rgb={data.urobilinogenArr4rgb}
        urobilinogenArrre0={data.urobilinogenArrre0}
        urobilinogenArrre1={data.urobilinogenArrre1}
        urobilinogenArrre2={data.urobilinogenArrre2}
        urobilinogenArrre3={data.urobilinogenArrre3}
        urobilinogenArrre4={data.urobilinogenArrre4}
        ketonecolor={data.ketonecolor}
        ketoneArr0={data.ketoneArr0}
        ketoneArr1={data.ketoneArr1}
        ketoneArr2={data.ketoneArr2}
        ketoneArr3={data.ketoneArr3}
        ketoneArr4={data.ketoneArr4}
        ketoneArrre0={data.ketoneArrre0}
        ketoneArrre1={data.ketoneArrre1}
        ketoneArrre2={data.ketoneArrre2}
        ketoneArrre3={data.ketoneArrre3}
        ketoneArrre4={data.ketoneArrre4}
        proteincolor={data.proteincolor}
        proteinArr0={data.proteinArr0}
        proteinArr1={data.proteinArr1}
        proteinArr2={data.proteinArr2}
        proteinArr3={data.proteinArr3}
        proteinArr4={data.proteinArr4}
        proteinArr5={data.proteinArr5}
        proteinArrre0={data.proteinArrre0}
        proteinArrre1={data.proteinArrre1}
        proteinArrre2={data.proteinArrre2}
        proteinArrre3={data.proteinArrre3}
        proteinArrre4={data.proteinArrre4}
        proteinArrre5={data.proteinArrre5}
        nitrituriacolor={data.nitrituriacolor}
        nitrituriaArr0b={data.nitrituriaArr0b}
        nitrituriaArr1b={data.nitrituriaArr1b}
        nitrituriaArrre0={data.nitrituriaArrre0}
        nitrituriaArrre1={data.nitrituriaArrre1}
        glucosecolor={data.glucosecolor}
        glucoseArr0={data.glucoseArr0}
        glucoseArr1={data.glucoseArr1}
        glucoseArr2={data.glucoseArr2}
        glucoseArr3={data.glucoseArr3}
        glucoseArr4={data.glucoseArr4}
        glucoseArr5={data.glucoseArr5}
        glucoseArrre0={data.glucoseArrre0}
        glucoseArrre1={data.glucoseArrre1}
        glucoseArrre2={data.glucoseArrre2}
        glucoseArrre3={data.glucoseArrre3}
        glucoseArrre4={data.glucoseArrre4}
        glucoseArrre5={data.glucoseArrre5}
        phcolor={data.phcolor}
        phArr0={data.phArr0}
        phArr1={data.phArr1}
        phArr2={data.phArr2}
        phArr3={data.phArr3}
        phArr4={data.phArr4}
        phArrre0={data.phArrre0}
        phArrre1={data.phArrre1}
        phArrre2={data.phArrre2}
        phArrre3={data.phArrre3}
        phArrre4={data.phArrre4}
        gravitycolor={data.gravitycolor}
        gravityArr0={data.gravityArr0}
        gravityArr1={data.gravityArr1}
        gravityArr2={data.gravityArr2}
        gravityArr3={data.gravityArr3}
        gravityArr4={data.gravityArr4}
        gravityArr5={data.gravityArr5}
        gravityArrre0={data.gravityArrre0}
        gravityArrre1={data.gravityArrre1}
        gravityArrre2={data.gravityArrre2}
        gravityArrre3={data.gravityArrre3}
        gravityArrre4={data.gravityArrre4}
        gravityArrre5={data.gravityArrre5}
        leukocytecolor={data.leukocytecolor}
        leukocyteArr0={data.leukocyteArr0}
        leukocyteArr1={data.leukocyteArr1}
        leukocyteArr2={data.leukocyteArr2}
        leukocyteArr3={data.leukocyteArr3}
        leukocyteArrre0={data.leukocyteArrre0}
        leukocyteArrre1={data.leukocyteArrre1}
        leukocyteArrre2={data.leukocyteArrre2}
        leukocyteArrre3={data.leukocyteArrre3}
        created_date={data.created_date}
      />
    ));

    return (
      <div className="Diagnosis_1">
        <div className="boxTitle">
          <span>
            <img src="/check_white.svg" />
          </span>
          <span className="idxDiv" style={{ display: "none" }}>
            번호
          </span>
          <span>검사지</span>
          <span>잠혈</span>
          <span>빌리루빈</span>
          <span>우로빌리노겐</span>
          <span>케톤체</span>
          <span>단백질</span>
          <span>아질산염</span>
          <span>포도당</span>
          <span>PH</span>
          <span>비중</span>
          <span>백혈구</span>
          <span>총 결과</span>
          <span>검사 결과 날짜</span>
        </div>
        <div className="diagnosisList_box">{diagnosisList}</div>
        {currentPageData.length > 0 && (
          <div className="pagination">
            <div
              className="minus"
              onClick={this.del_check}
              style={{ left: "12px" }}
            >
              <img className="file_minus" src="/file_minus.svg" />
              <span>삭제하기</span>
            </div>
            <button id="prev" onClick={this.handlePreviousPage}>
              <img src="/left.svg" alt="Previous" />
            </button>
            {this.renderPagination()}
            <button id="next" onClick={this.handleNextPage}>
              <img src="/right.svg" alt="Next" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Diagnosis_1;

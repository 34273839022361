import React, { Component } from "react";
import "./css/Faq.css";
import Faq_List from "./Faq_1.js";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";
import { Route, Routes } from "react-router-dom";
import Calendar from "./Calender";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_DATE: null,
      faqList: [],
      idx: null,
      get_title: null,
      get_contents: null,
      get_createdDate: null,
      edit_title: null,
      edit_contents: null,
      add_title: null,
      add_contents: null,
      defaulSelect: "제목",
      set_textSearch: null,
      getDate: null,
      currentPage : 1,
    };
  }

  componentDidMount() {
    $(".Navigation #menuBtn5 img").attr("src", "/menu5_green.svg");
    $(".Navigation #menuBtn5").css({
      color: "#368552",
      "background-color": "#5ab3795d",
      "font-weight": "bold",
    });
    this.getFaq();

    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    this.setState({
      today_DATE: year + "-" + month + "-" + day,
    });

    if (window.location.href.includes("num")) {
      const href = window.location.href;
      const numParam = href.split("num=")[1];
      const numValue =
        numParam && numParam.includes("#") ? numParam.split("#")[0] : numParam;
      this.setState(
        {
          idx: numValue,
        },
        () => {
          this.getFaq_detail();
        }
      );
    }
    // Add 추가
    if (window.location.href.includes("Faq_add")) {
      $(".Faq > .Faq_detail").css("display", "none");
      $(".Faq > .title").css("display", "none");
      $(".Faq > .Faq_edit").css("display", "flex");
      $(".Faq > .edit_title").css("display", "flex");
      $(".ck.ck-editor").css("display", "flex");
    }
  }

  selectSearch = (e) => {
    this.setState({
      defaulSelect: e.target.value,
    });
    if (e.target.value == "작성일") {
      $(".inputSearch").css("display", "none");
      $(".nice-dates input").css("display", "block");
    } else {
      $(".inputSearch").css("display", "flex");
      $(".nice-dates input").css("display", "none");
    }
  };
  handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      this.querySearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  textSearch = (e) => {
    this.setState({
      set_textSearch: e.target.value,
    });
  };

  querySearch = async () => {
    const defaulSelect = this.state.defaulSelect;
    const set_textSearch = this.state.set_textSearch;
    const dateVal = $(".nice-dates input").val();
    if (
      (set_textSearch == null ||
        set_textSearch == " " ||
        set_textSearch == "") &&
      (dateVal == null || dateVal == " " || dateVal == "")
    ) {
      alert("검색어 입력및 날짜를 선택해주세요.");
    } else {
      if (defaulSelect == "제목") {
        const res = await axios.get("/admin/get-title/faq" + set_textSearch)
        .then((res) => {
          this.setState({
            faqList: res.data.faq_res,
            currentPage: 1,
          });
        })
        .catch((error) => {
          alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
        })
      } else if (defaulSelect == "작성일") {
        const res = await axios.get("/admin/get-createdDate/faq" + dateVal)
        .then((res) => {
          this.setState({
            faqList: res.data.faq_res,
            currentPage: 1,
          });
        })
        .catch((error) => {
          alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
        })
      } else {
        alert("잘못된 입력입니다!");
      }
    }
    this.setState({ currentPage: 1 });
  };

  getFaq = async () => {
    const res = await axios.get("/admin/get/faq")
    .then((res) => {
      this.setState({
        faqList: res.data.faq_res,
        currentPage: 1,
      });
    })
    .catch((error) => {
      alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
    })
  };

  getFaq_detail = async () => {
    const idx = this.state.idx;
    const res = await axios.get("/admin/get2/faq" + idx)
    .then((res) => {
      const createdDate = res.data.faq_res[0].created_date.split("T")[0];
      this.setState({
        get_title: res.data.faq_res[0].title,
        get_contents: res.data.faq_res[0].contents,
        get_createdDate: createdDate,
      });
    })
    .catch((error) => {
      alert('정보를 불러오지 못했습니다. 관리자에게 문의해 주세요.');
    })
  };

  href_menu() {
    window.location.href = "/Admin_Page/Faq";
  }

  // Detail -> edit 수정
  edit() {
    $(".Faq > .Faq_detail").css("display", "none");
    $(".Faq > .title").css("display", "none");
    $(".Faq > .Faq_edit").css("display", "flex");
    $(".Faq > .edit_title").css("display", "flex");
    $(".ck.ck-editor").css("display", "flex");
  }

  edit_title = (e) => {
    var val = e.target.value;
    if (val == null || val == undefined || val == "null" || val.trim() === "") {
      val = null;
      this.setState({
        edit_title: val,
      });
    } else {
      this.setState({
        edit_title: val,
      });
    }
  };

  // edit_complete 수정 완료
  edit_complete = (async) => {
    let edit_list;
    let title = this.state.edit_title;
    let contents = this.state.edit_contents;

    if (title == null) {
      title = this.state.get_title;
    }
    if (contents == null) {
      contents = this.state.get_contents;
    }

    edit_list = { idx: this.state.idx, title: title, contents: contents };
    const res = axios
      .patch("/admin/patch/faq", edit_list)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) =>
        alert("수정되지 않았습니다. 관리자에게 문의해 주세요.")
      );
  };

  // edit_cancel 수정 취소
  edit_cancel() {
    $(".Faq > .Faq_detail").css("display", "flex");
    $(".Faq > .title").css("display", "flex");
    $(".Faq > .Faq_edit").css("display", "none");
    $(".Faq > .edit_title").css("display", "none");
    $(".ck.ck-editor").css("display", "none");
  }

  // Detail 삭제
  del = async () => {
    const checkedIds = [this.state.idx];
    const res = await axios
      .delete("/admin/delete/faq" + checkedIds)
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) =>
        alert("삭제하지 못했습니다. 관리자에게 문의해 주세요.")
      );
    window.location.href = "/Admin_Page/Faq";
  };

  // Add 추가
  add_title = (e) => {
    this.setState({
      add_title: e.target.value,
    });
  };

  // add_complete 추가 완료
  add_complete = (async) => {
    let add_list;
    let title = this.state.add_title;
    let contents = this.state.add_contents;

    if (
      title == null ||
      title == undefined ||
      title == "null" ||
      title.trim() === ""
    ) {
      title = null;
      alert("제목을 입력해주세요!");
      return;
    }
    if (
      contents == null ||
      contents == undefined ||
      contents == "null" ||
      contents.trim() === ""
    ) {
      contents = null;
      alert("문의사항 내용을 입력해주세요!");
      return;
    }
    add_list = { title: title, contents: contents };
    const res = axios
      .post("/admin/post/faq", add_list)
      .then((res) => {
        window.location.href = "/Admin_Page/Faq";
      })
      .catch((error) =>
        alert("문의사항을 추가하지 못했습니다. 관리자에게 문의해 주세요.")
      );
  };

  // add_cancel 추가 취소
  add_cancel() {
    window.location.href = "/Admin_Page/Faq";
  }

  render() {
    return (
      <div className="Faq">
        <Routes>
          {/* 리스트 */}
          <Route
            path="/"
            element={
              <>
                <div className="title">
                  <div className="title_text">문의사항</div>
                  <span>(한 페이지당 보이는 개수 : 10)</span>
                  <div className="serchBar">
                    <select
                      name="selectSearch"
                      id="selectSearch"
                      onChange={this.selectSearch}
                    >
                      <option value="제목" defaultValue>
                        제목
                      </option>
                      <option value="작성일">작성일</option>
                    </select>
                    <Calendar />
                    <input
                      type="text"
                      placeholder="검색"
                      className="inputSearch"
                      onChange={this.textSearch}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                    />
                    <img
                      className="querySearch"
                      src="/search.svg"
                      onClick={this.querySearch}
                    />
                  </div>
                </div>

                <div className="Faq_board">
                  <Faq_List faqList={this.state.faqList} currentPage={this.state.currentPage}/>
                </div>
              </>
            }
          />

          {/* 상세페이지 */}
          <Route
            path="/Faq_detail/*"
            element={
              <>
                <div className="title">
                  <img
                    className="double_left"
                    src="/double_left.svg"
                    alt="문의사항 메뉴로 나가기"
                    onClick={this.href_menu}
                  />
                  <div className="title_text">{this.state.get_title}</div>
                  <div className="title_date">
                    작성일 : {this.state.get_createdDate}
                  </div>
                </div>

                <div className="edit_title">
                  <img
                    className="double_left"
                    src="/double_left.svg"
                    alt="문의사항 메뉴로 나가기"
                    onClick={this.href_menu}
                  />
                  <div className="title_text">
                    제목 :{" "}
                    <input
                      className="edit_title"
                      type="text"
                      placeholder={this.state.get_title}
                      onChange={this.edit_title}
                    />
                  </div>
                  <div className="title_date">
                    수정일 : {this.state.today_DATE}
                  </div>
                </div>

                <div className="Faq_detail">
                  <div>
                    <div className="btnBox">
                      <img
                        src="/edit.svg"
                        id={this.state.idx}
                        onClick={this.edit}
                      />
                      <img src="/trash_white.svg" onClick={this.del} />
                    </div>
                  </div>
                  <div>{ReactHtmlParser(this.state.get_contents)}</div>
                </div>

                <div className="Faq_edit">
                  <div>
                    <div className="btnBox">
                      <img src="/cancel.svg" onClick={this.edit_cancel} />
                      <img
                        src="/check.svg"
                        id={this.state.idx}
                        onClick={this.edit_complete}
                      />
                    </div>
                  </div>
                  <div>
                    <CKEditor
                      className="custom_editor"
                      editor={ClassicEditor}
                      config={{
                        placeholder: "문의사항 내용을 입력해주세요",
                        toolbar: {
                          items: [
                            "bold",
                            // 'numberedList',
                            // 'bulletedList',
                            "|",
                            "undo",
                            "redo",
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                        language: "ko",
                      }}
                      data={this.state.get_contents}
                      onReady={(editor) => {
                        this.editor = editor;
                      }}
                      onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                          this.editor.ui.view.toolbar.element.remove();
                        }
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                          edit_contents: data,
                        });
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </div>
                </div>
              </>
            }
          />

          {/* 리스트 */}
          <Route
            path="/Faq_add/*"
            element={
              <>
                <div className="edit_title">
                  <img
                    className="double_left"
                    src="/double_left.svg"
                    alt="문의사항 메뉴로 나가기"
                    onClick={this.href_menu}
                  />
                  <div className="title_text">
                    제목 :{" "}
                    <input
                      className="edit_title"
                      type="text"
                      placeholder="제목을 입력해 주세요"
                      onChange={this.add_title}
                    />
                  </div>
                  <div className="title_date">
                    수정일 : {this.state.today_DATE}
                  </div>
                </div>

                <div className="Faq_edit">
                  <div>
                    <div className="btnBox">
                      <img src="/cancel.svg" onClick={this.add_cancel} />
                      <img
                        src="/check.svg"
                        id={this.state.idx}
                        onClick={this.add_complete}
                      />
                    </div>
                  </div>
                  <div>
                    <CKEditor
                      className="custom_editor"
                      editor={ClassicEditor}
                      config={{
                        placeholder: "문의사항 내용을 입력해주세요",
                        toolbar: {
                          items: [
                            "bold",
                            // 'numberedList',
                            // 'bulletedList',
                            "|",
                            "undo",
                            "redo",
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                        language: "ko",
                      }}
                      onReady={(editor) => {
                        this.editor = editor;
                      }}
                      onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                          this.editor.ui.view.toolbar.element.remove();
                        }
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                          add_contents: data,
                        });
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </div>
    );
  }
}

export default Faq;
